import { Modal, Box, ModalProps, Button } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';

interface IIdFailedProps extends ModalProps {
  children: React.ReactElement;
}

const IdFailed: React.FunctionComponent<IIdFailedProps> = (props) => {
  return (
    <Modal {...props}>
      <Box sx={FailModal}>
        <FailCard>
          <CloseBtn
            onClick={() =>
              //@ts-ignore
              props.onClose()
            }
          >
            <img src='/image/closegray.svg' />
          </CloseBtn>
          <h2>게임 아이디가 존재하지 않습니다.</h2>
          <h2>게임 아이디를 먼저 생성해 주세요.</h2>
        </FailCard>
      </Box>
    </Modal>
  );
};

export default IdFailed;

const FailModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  outline: 'none',
};

const FailCard = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 500px;
  padding: 20px;
  border-radius: 16px;
  box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px 0px;
  background-color: #04080e;
  box-sizing: border-box;
  text-align: center;
  align-items: center;
  h2 {
    color: white;
  }
`;

const CloseBtn = styled(Button)`
  /* padding-left: 96%; */
  margin-left: 90% !important;
`;

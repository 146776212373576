import { Modal, Box, Button, ModalProps, Fab } from '@mui/material';

import * as React from 'react';
import styled from 'styled-components';

import CheckIcon from '@mui/icons-material/Check';

interface IExportSuccessProps extends ModalProps {}

const ExportSuccess: React.FunctionComponent<IExportSuccessProps> = (props) => {
  return (
    <>
      <Modal {...props}>
        <Box sx={ModalCard}>
          <CommonCard>
            <CloseBtn
              onClick={() =>
                //@ts-ignore
                props.onClose()
              }
            >
              <img src='/image/closegray.svg' />
            </CloseBtn>
            <h2>내보내기에 성공하였습니다.</h2>
            <Fab color='primary' sx={buttonSx}>
              <CheckIcon />
            </Fab>
          </CommonCard>
        </Box>
      </Modal>
    </>
  );
};

export default ExportSuccess;

const buttonSx = {
  bgcolor: '#1FC7D4',
  pointerEvents: 'none',
};

const ModalCard = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  outline: 'none',
};

const CommonCard = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 500px;
  padding: 20px;
  border-radius: 16px;
  box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px 0px;
  background-color: #04080e;
  box-sizing: border-box;
  text-align: center;
  align-items: center;
  h2 {
    color: white;
  }
  p {
    color: white;
  }
`;

const CloseBtn = styled(Button)`
  /* padding-left: 96%; */
  margin-left: 90% !important;
`;

import styled from 'styled-components';

export const BuyStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  outline: 'none',
};

export const BuyBox = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 500px;
  padding: 20px;
  border-radius: 16px;
  box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px 0px;
  background-color: #ffffff;
  box-sizing: border-box;
  text-align: center;
  align-items: center;
`;

export const PaySection = styled.div`
  display: flex;

  flex-direction: column;
  align-items: center;

  .close-wrap {
    position: absolute;
    left: 90%;
    cursor: pointer;
  }
  p {
    margin-top: 40px;
    width: 390px;
    text-align: left;
    line-height: 1.5;
  }
  h2 {
    margin-top: 40px;
  }
`;

export const SuccessWrap = styled.div`
  margin-top: 20px;
  .title {
    margin-top: 20px;
    font-size: 20px;
    white-space: pre-line;
  }
`;

export const FailedWrap = styled.div`
  margin-top: 20px;
  .title {
    margin-top: 20px;
    font-size: 20px;
  }
`;

export const OpenSeaWrap = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  background-color: #1a2147;
  border-radius: 15px;
  width: 300px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OpenSeaBtn = styled.div`
  font-size: 30px;
  color: white;
  cursor: pointer;
  a {
    text-decoration: none;
    color: white;
  }
`;

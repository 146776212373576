import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface IPacksLevelProps {
  name: 'story' | 'character';
}

const PacksLevel: React.FunctionComponent<IPacksLevelProps> = ({ name }) => {
  const { t } = useTranslation();

  return (
    <LevelWrap>
      <Title>{t(`packinfos.${name}.info.title`)}</Title>
      <TableInfo>
        <LineWrap>
          <h2>{t(`packinfos.${name}.info.subtitle1`)}</h2>
          <h2>{t(`packinfos.${name}.info.subtitle2`)}</h2>
        </LineWrap>
        {(
          t(`packinfos.${name}.info.powers`, { returnObjects: true }) as Array<{
            grade: string;
            stock: string;
          }>
        ).map(({ grade, stock }, i: number) => (
          <LineWrap key={i}>
            <p>{grade}</p>
            <p>{stock}</p>
          </LineWrap>
        ))}
      </TableInfo>

      <InfoData>
        <h1>{t(`packinfos.${name}.info.subtitle4`)}</h1>
      </InfoData>
      <LineWrap>
        <h2>{t(`packinfos.${name}.info.subtitle3`)}</h2>
        <h2>{t(`packinfos.${name}.info.totalstock`)}</h2>
      </LineWrap>
    </LevelWrap>
  );
};

export default PacksLevel;

const LevelWrap = styled.div`
  margin-top: 40px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Title = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  max-width: 620px;
  width: 100%;
  height: 70px;
  border-radius: 8px;
  background-color: rgb(25, 33, 71);
  color: rgb(233, 240, 254);
  font-size: 1.125rem;
  line-height: 1.40625rem;
  font-weight: bold;
  font-size: 30px;
`;

const InfoData = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  h1 {
    color: white;
  }
`;

const TableInfo = styled.div`
  border-top: 1px solid rgb(30, 36, 63);

  h2,
  p {
    font-size: 20px;
    width: 50%;
    text-align: center;
  }
`;

const LineWrap = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* margin-top: 10px; */
  padding: 15px;
  border-bottom: 1px solid rgb(30, 36, 63);

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    p {
      margin-top: 5px;
    }
  }
`;

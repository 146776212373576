import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";

import styled from "styled-components";
import i18n from "../i18n";

const MenuMainWrapper = styled.div`
  position: absolute;
  left: 90%;
`;

const MenuWrapper = () => {
  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <MenuMainWrapper>
      <PopupState variant="popover" popupId="demo-popup-menu">
        {(popupState) => (
          <>
            <Button
              variant="contained"
              {...bindTrigger(popupState)}
              style={{
                width: "120px",
                height: "40px",
                fontSize: "20px",
                fontWeight: "bold",
                color: "white",
                borderRadius: "15px",
                backgroundColor: "transparent",
                border: "2px solid #1fc7d4",
              }}
            >
              {i18n.language === "en" ? "ENG" : "KOR"}
            </Button>
            <Menu {...bindMenu(popupState)}>
              <div onClick={() => changeLanguage("ko")}>
                <MenuItem onClick={popupState.close}>KOR</MenuItem>
              </div>
              <div onClick={() => changeLanguage("en")}>
                <MenuItem onClick={popupState.close}>ENG</MenuItem>
              </div>
            </Menu>
          </>
        )}
      </PopupState>
    </MenuMainWrapper>
  );
};

export default MenuWrapper;

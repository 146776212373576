import styled from "styled-components";

export const ContentWrap = styled.div`
  min-width: 1920px;
  margin: 0 auto;
  padding: 100px 0;
  background-color: black;
`;

export const Box = styled.div`
  width: calc(25% - (45px / 4));
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 15px;
  .img-wrap {
    width: 100%;
    margin-bottom: 10px;
    img,
    video {
      width: 100%;
      aspect-ratio: 1;
    }
  }
  .title {
    font-weight: bold;
  }
  button {
    width: 100%;
    height: 30px;
    line-height: 30px;
    margin-top: 10px;
    padding: 0;
    border: 1px solid #000;
    border-radius: 5px;
    background-color: transparent;
    transition: 0.5s;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }
`;

export const MintingGuideWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
`;

export const GuideBtnWrap = styled.div`
  display: flex;
  gap: 10px;
`;

export const ConnectBoxWrap = styled.div`
  padding-top: 50px;
  margin: 0 auto;
`;
export const ConnectInfo = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ConnectBox = styled.div`
  /* width: 600px; */
  /* height: 300px; */
  background-color: #04080e;
  border-radius: 50px;
  display: flex;
  /* flex-direction: column; */
  /* justify-content: center; */
  align-items: center;
  padding-top: 20px;
  color: white;
  text-align: center;
  padding: 20px;

  button {
    min-width: 80px;
    max-width: 160px;
    padding: 10px;
    border-radius: 50px;
    border: none;
    background-color: rgb(255, 255, 255);
    font-weight: bold;
    color: rgb(0, 0, 0);
    cursor: pointer;
    box-shadow: rgb(250 250 250 / 30%) 0px 6px 0px -2px;
  }
  img {
    border-radius: 15px;
  }
`;

export const GridVideoWrap = styled.div`
  padding-top: 50px;
  margin: 0 auto;
  width: 1550px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  video {
    width: 500px;
    height: 500px;
  }
`;

export const CoinCheckWrap = styled.div`
  display: flex;
  max-width: 360px;
  width: 100%;
  height: 56px;
  text-align: center;
  background-color: rgb(19, 24, 47);
  border-radius: 28px;
  box-sizing: border-box;
  margin-bottom: 20px;
  img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
`;

export const CoinCheckBtn = styled.div<{ isActive: boolean }>`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 50%;
  border-radius: 28px;
  background-color: ${({ isActive }) =>
    isActive ? "rgb(91, 21, 213)" : "transparent"};
  padding: 16px 50px;
  font-size: 1.125rem;
  line-height: 1.40625rem;
  cursor: pointer;
`;

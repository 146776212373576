import { positions } from '@mui/system';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface ITeamProps {}

const Team: React.FunctionComponent<ITeamProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <h1
        style={{
          color: 'white',
          textAlign: 'center',
          paddingTop: 40,
          fontSize: 50,
        }}
      >
        Team
      </h1>
      <TeamWrap>
        <ContentsWrap>
          {(
            t('profile.profilecard', { returnObjects: true }) as Array<{
              position: string;
              name: string;
              career: string;
            }>
          ).map(({ position, name, career }, i: number) => {
            return (
              <ProfileWrap key={i}>
                <ImgWrap>
                  <img src={`/image/profiles/profile${i}.jpg`} alt='profile' />
                </ImgWrap>
                <DescWrap>
                  <Position>{position}</Position>
                  <Name>{name}</Name>
                  <Career>{career}</Career>
                </DescWrap>
              </ProfileWrap>
            );
          })}
        </ContentsWrap>
      </TeamWrap>
    </>
  );
};

export default Team;

const TeamWrap = styled.div`
  width: 100%;
  max-width: 1000px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding-top: 80px;
  padding-bottom: 80px;
  margin: 0 auto;
`;

const SlideWrap = styled.div`
  /* position: absolute;
  top: 65;
  right: 0;
  width: 73%;
  overflow-x: scroll; */
  display: flex;
  justify-content: center;
`;

const Position = styled.div`
  white-space: pre-line;
  font-weight: 800;
  font-size: 10;
  line-height: 1.08;
  height: 20;
`;

const Name = styled.div`
  font-weight: 800;
  font-size: 12;
  line-height: 1.08;
  margin-top: 10px;
  color: white;
`;

const Career = styled.div`
  font-weight: 800;
  font-size: 12;
  line-height: 1.08;
  margin-top: 10px;
  color: white;
  white-space: pre-line;
`;

const ImgWrap = styled.div`
  border-radius: 100%;
  img {
    height: 300px;
  }
`;

const ProfileWrap = styled.div`
  margin-right: 50px;
  color: white;
  display: flex;
  gap: 60px;
  margin-bottom: 30px;
`;

const ContentsWrap = styled.div`
  display: flex;
  max-width: 1200px;
  flex-direction: column;
  justify-content: flex-start;
`;

const DescWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 20px;
`;

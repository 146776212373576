import styled from 'styled-components';

export const WebtoonWrap = styled.div`
  min-width: 1920px;
  margin: 0 auto;
  background-color: #000101;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WebtoonTitle = styled.h1`
  margin-top: 100px;
  color: white;
  font-size: 100px;
`;

export const WebtoonCardWrap = styled.div`
  width: 1500px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 25px;
  row-gap: 100px;
  margin-top: 100px;
  margin-bottom: 100px;
  justify-content: center;
`;

export const WebtoonCard = styled.div`
  width: 350px;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #1a2147;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  h1 {
    color: white;
    text-align: center;
  }
  .img-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      background-color: black;
      width: 300px;
      height: 300px;
      border-radius: 15px;
      color: white;
      font-size: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      aspect-ratio: 1;
    }
  }
  a {
    background-color: #3aa55b;
    width: 300px;
    height: 50px;
    padding: 10px 20px;
    color: #fff;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
    border-radius: 10px;
    margin-top: 30px;
    cursor: pointer;
    border: none;
    :hover {
      opacity: 0.7;
    }
  }
`;

export const OpacityWrap = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 30px;
  font-weight: bold;
`;

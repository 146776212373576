import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Web3ReactProvider } from '@web3-react/core';
import getLibrary from '../library';
import React from 'react';

interface IProvidersProps {}

const theme = createTheme({
  palette: {
    primary: {
      main: '#aeb6bc',
    },
  },
});

const Providers: React.FunctionComponent<IProvidersProps> = ({ children }) => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </Web3ReactProvider>
  );
};

export default Providers;

import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import WalletCheck from '../../components/Modal/WalletCheck';
import PacksDetail from '../../components/Packs/PacksDetail';

interface IPacksProps {}

const whitelist = ['story', 'character'];

const Packs: React.FunctionComponent<IPacksProps> = (props) => {
  const { name } = useParams() as {
    name: 'story' | 'character';
  };

  const [isWalletCheckOpen, setIsWalletCheckOpen] =
    React.useState<boolean>(false);

  const navigate = useNavigate();

  const handleWalletCheckClose = React.useCallback(() => {
    setIsWalletCheckOpen(false);
    navigate('/', { replace: true });
  }, [isWalletCheckOpen, setIsWalletCheckOpen]);

  React.useEffect(() => {
    if (!whitelist.includes(name)) {
      navigate('/', { replace: true });
    }
    if (!window.localStorage.getItem('wallet')) {
      //페이지 렌더링시 web3Start.account 가 없고, 현재 로컬스토리지에 'wallet'이 없다면 지갑연결 실행
      setIsWalletCheckOpen(true);
    }
  }, []);

  return (
    <>
      <Header />
      <ContentWrap>
        <ConnectBoxWrap>
          <PacksDetail name={name} />
        </ConnectBoxWrap>
      </ContentWrap>
      <Footer />
      {/* @ts-ignore */}
      <WalletCheck open={isWalletCheckOpen} onClose={handleWalletCheckClose} />
    </>
  );
};

export default Packs;

const ContentWrap = styled.div`
  min-width: 1920px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 100px 0;
  background-color: black;
`;

const ConnectBoxWrap = styled.div`
  padding-top: 50px;
  margin: 0 auto;
`;

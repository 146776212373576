import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

import { IWalletIDRes } from "../types";

export const initialWallet: IWallet = {
  wId: 0,
  address: "",
  status: false,
  createdAt: "",
  email: "",
  phone: "",
  name: "",
  imageUrl: "",
  coin: "",
  isLogin: undefined,
};

interface IWallet extends IWalletIDRes {
  isLogin?: boolean;
}

const walletAtom = atom<IWallet>({
  key: "wallet",
  default: initialWallet,
  effects_UNSTABLE: [persistAtom],
});

export default walletAtom;

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Footer from '../../components/Footer';
import Header from '../../components/Header';

interface IPersonalProps {}

const Personal: React.FunctionComponent<IPersonalProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <PersonalWrap>
        <Tilte>{t('personal.title1')}</Tilte>
        <Desc>{t('personal.desc1')}</Desc>
        <Tilte>{t('personal.title2')}</Tilte>
        <Desc>{t('personal.desc2')}</Desc>
        <Tilte>{t('personal.title3')}</Tilte>
        <Desc>{t('personal.desc3')}</Desc>
        <Tilte>{t('personal.title4')}</Tilte>
        <Desc>{t('personal.desc4')}</Desc>
        <Alphabet>{t('personal.alphabet1')}</Alphabet>

        <Tilte>{t('personal.title5')}</Tilte>
        <Desc>{t('personal.desc5')}</Desc>
        <Alphabet>{t('personal.alphabet2')}</Alphabet>
        <Desc>{t('personal.desc6')}</Desc>
        <Alphabet>{t('personal.alphabet3')}</Alphabet>

        <Tilte>{t('personal.title7')}</Tilte>
        <Desc>{t('personal.desc7')}</Desc>
        <Alphabet>{t('personal.alphabet4')}</Alphabet>
        <Desc>{t('personal.desc8')}</Desc>

        <Tilte>{t('personal.title8')}</Tilte>
        <Desc>{t('personal.desc10')}</Desc>
        <Alphabet>{t('personal.alphabet5')}</Alphabet>
        <Desc>{t('personal.desc11')}</Desc>
        <Alphabet>{t('personal.alphabet6')}</Alphabet>

        <Tilte>{t('personal.title9')}</Tilte>
        <Desc>{t('personal.desc12')}</Desc>

        <Tilte>{t('personal.title10')}</Tilte>
        <Desc>{t('personal.desc13')}</Desc>
        <Alphabet>{t('personal.alphabet7')}</Alphabet>

        <Tilte>{t('personal.title11')}</Tilte>
        <Desc>{t('personal.desc14')}</Desc>
        <Alphabet>{t('personal.alphabet8')}</Alphabet>
        <Desc>{t('personal.desc15')}</Desc>

        <Tilte>{t('personal.title12')}</Tilte>
        <Desc>{t('personal.desc16')}</Desc>

        <Tilte>{t('personal.title13')}</Tilte>
        <Desc>{t('personal.desc17')}</Desc>
        <Tilte>{t('personal.title14')}</Tilte>
        <Desc>{t('personal.desc18')}</Desc>
        <Tilte>{t('personal.title15')}</Tilte>
        <Desc>{t('personal.dsec19')}</Desc>
      </PersonalWrap>
      <Footer />
    </>
  );
};

export default Personal;

const PersonalWrap = styled.div`
  min-width: 1920px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 100px 400px;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

const Tilte = styled.h1``;

const Desc = styled.p`
  line-height: 1.5;
  white-space: pre-line;
`;

const Alphabet = styled.p`
  white-space: pre-line;
  line-height: 2;
  padding-left: 5%;
  padding-right: 5%;
`;

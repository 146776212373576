import { Modal, Box, Button, ModalProps } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';

interface IMintingModalProps extends ModalProps {
  children: React.ReactElement;
}

const MintingModal: React.FunctionComponent<IMintingModalProps> = (props) => {
  return (
    <>
      <Modal {...props}>
        <Box sx={Minting}>
          <MintingCard>
            <h2>민팅중입니다....</h2>
          </MintingCard>
        </Box>
      </Modal>
    </>
  );
};

export default MintingModal;

const Minting = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  outline: 'none',
};

const MintingCard = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 500px;
  padding: 20px;
  border-radius: 16px;
  box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px 0px;
  background-color: #04080e;
  box-sizing: border-box;
  text-align: center;
  align-items: center;
  h2 {
    color: white;
  }
`;

const CloseBtn = styled(Button)`
  /* padding-left: 96%; */
  margin-left: 90% !important;
`;

import styled from 'styled-components';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

export const Bgwrap = styled.div`
  /* width: 100%;
  height: 100%; */
  min-width: 1920px;
  margin: 0 auto;
  background-image: url('/image/export-info-bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: #000101;
  position: relative;
  padding-top: 300px;
  padding-bottom: 300px;
`;

export const LogoWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
`;

export const MainTitle = styled.h1`
  color: #fff;
  text-align: center;
  margin-bottom: 150px;
`;

export const SubTitle = styled.h1`
  text-align: center;
  color: #ffe27e;
  font-size: 50px;
`;

export const DescWrap = styled.div`
  max-width: 1600px;
  padding-top: 130px;
  margin: 0 auto;
  h1 {
    color: white;
    font-size: 30px;
    padding-left: 130px;
  }
  h2 {
    color: white;
    font-size: 25px;
  }
  span {
    color: #ffe27e;
  }
  .game5-text {
    margin-left: 40px;
  }
`;

export const ImgWrap1 = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 150px;
`;

export const ImgWrap2 = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 150px;
`;

export const SubDescWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const Pointer1 = styled.div`
  position: absolute;
  left: 50%;
`;

export const Pointer2 = styled.div`
  position: absolute;
  top: 50%;
  right: 12%;
`;

export const ImgWrap3 = styled.div`
  position: relative;
  margin-bottom: 140px;
`;

export const Pointer3 = styled.div`
  position: absolute;
  top: 8%;
  right: 14%;
`;

export const ImgWrap4 = styled.div`
  margin-bottom: 230px;
  position: relative;
`;

export const Pointer4 = styled.div`
  position: absolute;
  top: 75%;
  right: 29%;
`;

export const ImgWrap5 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 200px;
`;

export const ImgWrap6 = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 50px;
  .inventory {
    width: 700px;
  }
  h2 {
    width: 300px;
  }
`;

export const BarWrap = styled.div`
  position: absolute;
  top: 22%;
  img {
    width: 800px;
  }
`;

export const Desc1 = styled.h2`
  position: absolute;
  top: 19.5%;
  left: 11%;
`;
export const Desc2 = styled.h2`
  position: absolute;
  top: 19.5%;
  right: 5%;
`;
export const Desc3 = styled.h2`
  position: absolute;
  top: 34%;
  right: 5%;
`;
export const Desc4 = styled.h2`
  position: absolute;
  top: 44.5%;
  left: 11%;
`;
export const Desc5 = styled.h2`
  position: absolute;
  top: 65%;
  left: 12%;
`;
export const Desc6 = styled.h2`
  position: absolute;
  top: 72%;
  right: 5%;
`;
export const Desc7 = styled.h2`
  position: absolute;
  bottom: 3%;
  right: 5%;
`;

export const TextWrap = styled.div`
  line-height: 1.5;
  padding-left: 100px;
  margin-bottom: 200px;
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  color: white;
  font-size: 25px;
  h2 {
    margin: 0;
  }
`;

export const Arrow = styled(ArrowRightIcon)`
  font-size: 2.5rem;
`;

export const ImgWrap7 = styled.div`
  display: flex;
  justify-content: space-between;
  img {
    width: 500px;
  }
`;

export const TextWrap2 = styled.div`
  line-height: 1.5;
  padding-left: 100px;
  padding-top: 200px;
`;

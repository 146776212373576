import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  StyledHeader,
  ContentsWrapper,
  LogoWrapper,
  Logo,
  WalletWrap,
  ConnectBtn,
  NavWrap,
  MaskWrap,
} from '../pages/Home/styles';
import MenuWrapper from './Menu';
import i18n from '../i18n';

import { useRecoilState } from 'recoil';

import { IWalletRequest } from '../types';
import walletAtom from '../atoms/wallet';
import { connectWallet } from '../utils/api';

import { useWeb3React } from '@web3-react/core';
import { injectedConnector } from '../connector/index';
import walletAccountAtom from '../atoms/walletAccount';

// @ts-ignore
import Web3Token from 'web3-token';
import web3 from '../connection/web3';
import { changeNetwork } from '../utils';
import { deeplink } from '../constants';

interface IHeaderProps {}

const Header: React.FunctionComponent<IHeaderProps> = (props) => {
  const [wallet, setWallet] = useRecoilState(walletAtom);
  const [error, setError] = React.useState<string | null>(null);
  const [isHide, setIsHide] = React.useState<boolean>(false);

  const [walletAccount, setWalletAccount] = useRecoilState(walletAccountAtom);

  // const [isConnectOpen, setIsConnectOpen] = React.useState<boolean>(false);
  // const [isLoading, setIsLoading] = React.useState<boolean>(false);
  // const [qrValue, setQrValue] = React.useState<IQr | null>(null);
  // const [webId, setWebId] = React.useState<string>("");

  // const { minutes, seconds, restart } = useTimer({
  //   expiryTimestamp: moment(moment(moment().add(30, "minutes"))).toDate(),
  // });

  const web3Start = useWeb3React();

  // console.log('33', web3Start);

  // 사이트 접속시 월렛 연결 로직
  // web3Start에는 월렛 활성화여부(연결 여부), chainId, account 등등이 담겨있다.
  React.useEffect(() => {
    if (!web3Start.account && window.localStorage.getItem('wallet')) {
      //페이지 렌더링시 web3Start.account 가 없고, 현재 로컬스토리지에 'wallet'이 없다면 지갑연결 실행
      web3Login();
    }
    if (web3Start.active) {
      //web3Start가 활성화 되있을경우 아래 로직 실행
      if (web3Start.account && web3Start.chainId) {
        // account, chainId 가 모두 있을때 실행?...
        if (web3Start.connector === injectedConnector) {
          //web3Start.connector 초기값은 undefined injectedConnector은 연결 되었을때 나타남 연결이 되었다면 localStorage, recoil에 wallet 정보를 저장한다.
          window.localStorage.setItem('wallet', 'metamask');

          //recoil에 wallet 정보 저장 시키기
          setWalletAccount((prev) => ({
            ...prev,
            chainId: web3Start.chainId!,
            wallet: 'metamask',
            account: web3Start.account!,
          }));
        }
      }
    }
    //web3Start 가 변경되면 다시 실행
  }, [web3Start]);

  //월렛 연결 함수
  const web3Login = async () => {
    await web3Start.activate(injectedConnector, async (error) => {
      console.log(error.name);
      console.log(error.message);

      if (
        error.name === 'UnsupportedChainIdError' ||
        error.name === 'e' ||
        error.message.indexOf('Unsupported chain id') !== -1
      ) {
        //위 에러일땐 클레이튼 네트워크로 변경
        await changeNetwork({ networkName: 'klaytn', setError });
      } else if (
        error.name === 'NoEthereumProviderError' ||
        error.name === 't'
      ) {
        // 모바일일땐 deeplink 전달 deeplink ("https://metamask.app.link/dapp/www.meta-soul.kr/")
        window.open(deeplink);
      }
    });

    if (web3Start.account && web3Start.chainId) {
      //web3Start.connector 초기값은 undefined injectedConnector은 연결 되었을때 나타남 연결이 되었다면 localStorage, recoil에 wallet 정보를 저장한다.
      if (web3Start.connector === injectedConnector) {
        window.localStorage.setItem('wallet', 'metamask');

        setWalletAccount((prev) => ({
          ...prev,
          chainId: web3Start.chainId!,
          wallet: 'metamask',
          account: web3Start.account!,
        }));
      }
    }
  };

  // ********* 싸이클럽 지갑 연결시 로직 **************
  // const handleOpen = React.useCallback(() => {
  //   const deepLink =
  //     "https://newapptest6.page.link/?apn=io.cyclub.app.dev&ibi=io.cyclub.app.dev&link=https://devtest.metaground.io/app?param=";

  //   const dateAfter30m = moment().add(30, "minutes");

  //   const webId = uuidv4();
  //   setIsConnectOpen(true);
  //   setQrValue({
  //     merchantId: 3,
  //     command: "connect",
  //     webId,
  //     expiryTime: dateAfter30m.format("YYYY-MM-DDTHH:mm:ss.000000Z"),
  //     // expiryTime: "2022-02-24T18:38:20.322751+09:00",
  //   });
  //   restart(dateAfter30m.toDate());
  //   // TODO: 30분 후 시간 만료되면 큐알 재생성이나 모달 닫게 수정 필요
  //   setIsLoading(true);
  //   setWebId(webId);

  //   apicall();
  //   if (isMobile) {
  //     window.open(
  //       `${deepLink}${JSON.stringify({
  //         merchantId: 3,
  //         command: "connect",
  //         webId,
  //         expiryTime: dateAfter30m.format("YYYY-MM-DDTHH:mm:ss.000000Z"),
  //       })}`
  //     );
  //     setIsLoading(true);
  //     setWebId(webId);

  //     apicall();
  //   }
  // }, []);
  // const handleClose = React.useCallback(() => {
  //   setIsConnectOpen(false);
  //   setIsLoading(false);
  // }, []);

  // const apicall = async () => {
  //   try {
  //     const result = await getWalletId(webId);

  //     if (result.status) {
  //       setWallet({ ...result, isLogin: true });
  //     }
  //     console.log(result);
  //     console.log("3323", wallet);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  // useInterval(
  //   () => {
  //     apicall();
  //   },
  //   isLoading ? 2000 : null
  // );

  const handleConnectWallet = React.useCallback(async () => {
    //useEffect로 진행하는 월렛연결이 아닌, 클릭시 월렛 연결하는 로직
    console.log(3);
    if (web3Start.active) {
      // 기존에 이미 연결된 버튼을 다시 누르면 비활성화 시키고 recoil value 지워줌
      web3Start.deactivate();
      window.localStorage.removeItem('wallet');
      setWalletAccount({
        chainId: 1,
        wallet: '',
        account: '',
        token: undefined,
      });
    } else {
      // 연결이 안되어 있다면 월렛 연결함수 실행
      web3Login();
    }
  }, [web3Start]);

  const getTokenType = React.useCallback(async () => {
    let token: string, type: 'web3' | 'klaytn';

    // @ts-ignore
    token = await Web3Token.sign(
      (msg: any) => web3.eth.personal.sign(msg, walletAccount.account!, ''),
      {
        // domain: "https://www.meta-soul.kr/",
        statement: '',
        expires_in: '9999 days',
        // not_before: new Date(Date.now() - 24 * 60 * 60 * 1000),
      }
    );

    type = 'web3';

    return { token, type };
    //토큰 값, 종류를 가져오는 type을 가져오는 로직, type은 web3로 필요하기에 고정값으로 넣어줌
  }, [walletAccount]);

  const sendToken = React.useCallback(async () => {
    try {
      let token = walletAccount.token || '';
      let type: 'web3' | 'klaytn' = 'web3';

      if (!token) {
        //리코일에 토큰 타입이 없다면 getTokenType 실행해 리코일에 저장 시킴
        const tokenType = await getTokenType();

        token = tokenType.token;
        type = tokenType.type;
        setWalletAccount((prev) => ({ ...prev, token }));

        //토큰을 저장해 메타소울 서버로 전송하는 함수
        const postToken: IWalletRequest = await connectWallet(token, type);
        // console.log(postToken);
      }
    } catch (error) {
      console.log('22', error);
    }
  }, [walletAccount]);

  React.useEffect(() => {
    //로그인시 지갑주소 없다면 서명을 받는 로직
    if (walletAccount.account) {
      sendToken();
    }
  }, [walletAccount]);

  return (
    <StyledHeader>
      <ContentsWrapper>
        <LogoWrapper>
          <Link to='/'>
            <Logo src='/image/logo.png' />
          </Link>
        </LogoWrapper>

        <NavWrap>
          <li>
            <Link to='/mint'>MINTING</Link>
          </li>

          <li className='info'>
            INFO
            <ul className='hover-wrap'>
              <li>
                <Link to='/game-info' className='menu game-info'>
                  GAME INFO
                </Link>
              </li>
              <li>
                <Link to='/nft-info' className='menu nft-info'>
                  NFT INFO
                </Link>
              </li>
              <li>
                <Link to='/export-info' className='menu'>
                  EXPORT INFO
                </Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to='/webtoon'>WEBTOON</Link>
          </li>
          <li>
            <a
              href={
                i18n.language === 'en'
                  ? 'https://soul-meta.gitbook.io/meta-soul_en/'
                  : 'https://soul-meta.gitbook.io/meta-soul/'
              }
              target='_blank'
            >
              WHITE PAPER
            </a>
          </li>
          <li>
            <Link to='/mypage'>MYPAGE</Link>
          </li>
        </NavWrap>

        <MenuWrapper />

        {/* {walletAccount.account !== "" && (
          <MyPageWrap>
            <Link to="/mypage" style={{ textDecoration: "none" }}>
              <MyPageBtn>마이페이지</MyPageBtn>
            </Link>
          </MyPageWrap>
        )} */}

        <WalletWrap onClick={handleConnectWallet}>
          <ConnectBtn>
            {web3Start.active
              ? `${walletAccount.account?.slice(0, 5)}` +
                '...' +
                `${walletAccount.account?.slice(
                  -5,
                  walletAccount.account?.length - 1
                )}`
              : 'Connects wallet'}
          </ConnectBtn>
        </WalletWrap>

        {/* ******* 싸이클럽 연결시 필요한 부분 ************ */}
        {/* <Modal open={isConnectOpen} onClose={handleClose}>
          <Box sx={LoginStyle}>
            <LoginBox>
              <WalletSection>
                
              </WalletSection>
              <CyclubWrap>
                <h2>혹시 싸이클럽 앱이 없으신가요?</h2>
                <CyclubBtn
                  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=io.cyclub.app"
                    )
                  }
                >
                  싸이클럽 앱 설치하기
                </CyclubBtn>
              </CyclubWrap>
            </LoginBox>
          </Box>
        </Modal> */}
      </ContentsWrapper>
    </StyledHeader>
  );
};

export default Header;

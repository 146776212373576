import React from "react";
import styled from "styled-components";

const NoMatch = () => {
  return (
    <NoMatchWrap>
      <h1>There's nothing here: 404! </h1>
    </NoMatchWrap>
  );
};

export default NoMatch;

const NoMatchWrap = styled.div`
  min-width: 1920px;
  min-height: 100vh;
  margin: 0 auto;
  background-color: #000101;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    color: white;
    font-size: 150px;
  }
`;

import React from "react";
import { Navigation, Autoplay } from "swiper";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

import "swiper/css";
import "swiper/css/navigation";

import PlayerItem from "./PlayerItem";

const MySwiper = styled(Swiper)`
  width: 1000px;
  height: 300px;
  position: relative;
`;

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  height: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const SwiperWrapper = () => {
  return (
    <>
      <MySwiper
        slidesPerView={3}
        spaceBetween={30}
        navigation={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
        loopFillGroupWithBlank={true}
        modules={[Navigation, Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          <PlayerItem
            url={"/video/slide1.mp4"}
            playing
            muted
            loop
            playsinline
            width={300}
            height={300}
          />
        </SwiperSlide>
        <SwiperSlide>
          <PlayerItem
            url={"/video/slide2.mp4"}
            playing
            muted
            loop
            playsinline
            width={300}
            height={300}
          />
        </SwiperSlide>
        <SwiperSlide>
          <PlayerItem
            url={"/video/slide3.mp4"}
            playing
            muted
            loop
            playsinline
            width={300}
            height={300}
          />
        </SwiperSlide>
        <SwiperSlide>
          <PlayerItem
            url={"/video/slide4.mp4"}
            playing
            muted
            loop
            playsinline
            width={300}
            height={300}
          />
        </SwiperSlide>
        <SwiperSlide>
          <PlayerItem
            url={"/video/slide5.mp4"}
            playing
            muted
            loop
            playsinline
            width={300}
            height={300}
          />
        </SwiperSlide>
        <SwiperSlide>
          <PlayerItem
            url={"/video/slide6.mp4"}
            playing
            muted
            loop
            playsinline
            width={300}
            height={300}
          />
        </SwiperSlide>
      </MySwiper>
    </>
  );
};

export default SwiperWrapper;

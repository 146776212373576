import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ReactPlayer from 'react-player';
import {
  MintSelectWrap,
  NftWrap,
  PriceWrap,
  DetailBtnWrap,
  LeftWrap,
  RightWrap,
  OpacityWrap,
  CountdownWrap,
} from './styles';

import { useTranslation } from 'react-i18next';

import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { getTokenPrice } from '../../utils';
import { useTimer } from 'react-timer-hook';
import { useRecoilState } from 'recoil';
import priceAtom from '../../atoms/price';

const now = moment().format('YYYY-MM-DD HH:mm:ss');
const date = [
  '2022-03-02 15:00:00',
  '2022-03-03 15:00:00',
  '2022-03-04 15:00:00',
  '2022-03-08 15:00:00',
  '2022-03-09 15:00:00',
  '2022-03-10 15:00:00',
];

const tabs = ['STORY', 'CHARACTER', 'FIGURE'];

const MintPage: React.FC = () => {
  const { days, hours, minutes, seconds } = useTimer({
    expiryTimestamp: moment(
      moment('2022-05-25 15:00:00', 'YYYY-MM-DD HH:mm:ss')
    ).toDate(),
  });

  const [currentTab, setCurrentTab] = React.useState<string>('STORY');
  const [klayPrice, setKlayPrice] = useRecoilState(priceAtom);

  const handleClickTab = React.useCallback(
    (item: string) => () => {
      setCurrentTab(item);
    },
    [currentTab]
  );

  const setToken = React.useCallback(async () => {
    const tokenPrice = await getTokenPrice();
    if (tokenPrice) {
      setKlayPrice(
        parseFloat((10000 / tokenPrice['klay-token'].krw).toFixed(2))
      );
    }
  }, []);

  React.useEffect(() => {
    setToken();
  }, []);

  const { t } = useTranslation();

  return (
    <>
      <Header />
      <MintSelectWrap>
        <TabWrap>
          {tabs.map((item, i) => (
            <TabBtn key={i} onClick={handleClickTab(item)}>
              <TabTitle currentTab={currentTab} item={item}>
                {item}
              </TabTitle>
            </TabBtn>
          ))}
        </TabWrap>

        {currentTab === 'FIGURE' && (
          <>
            {Object.keys(t('infos', { returnObjects: true })).map(
              (title, i) => {
                const isOpen = moment(
                  '2022-05-25 15:00:00',
                  'YYYY-MM-DD HH:mm:ss'
                ).isBefore(now);

                return (
                  <NftWrap key={i}>
                    <LeftWrap>
                      <div>
                        <h1 className='title'>
                          {t(`infos.${title}.description.0`)}{' '}
                          <span>NFT MINTING</span>
                        </h1>
                      </div>
                      <h2>{t('mintselect.description')}</h2>
                      <PriceWrap>
                        <h1>150 KLAY</h1>
                      </PriceWrap>

                      <DetailBtnWrap>
                        <Link
                          to={isOpen ? '#' : `/mint/${title}`}
                          style={{ textDecoration: 'none', color: 'white' }}
                        >
                          {t('mintselect.detailbtn')}
                        </Link>
                      </DetailBtnWrap>
                    </LeftWrap>
                    <RightWrap>
                      <ReactPlayer
                        url={`/video/${title}.mp4`}
                        playing
                        muted
                        loop
                        width='100%'
                        height='100%'
                        style={{ borderRadius: '50%', marginLeft: 20 }}
                      />
                    </RightWrap>
                    {isOpen && (
                      <OpacityWrap>{t(`mintselect.opendate6`)}</OpacityWrap>
                    )}
                  </NftWrap>
                );
              }
            )}
          </>
        )}
        {currentTab === 'CHARACTER' && (
          <NftWrap>
            <LeftWrap>
              <div>
                <h1 className='title'>
                  {t(`packinfos.${currentTab.toLowerCase()}.selecttitle`)}
                  <span> NFT MINTING</span>
                </h1>
              </div>
              <h2>
                {t(`packinfos.${currentTab.toLowerCase()}.selectdescription`)}
              </h2>
              <PriceWrap>
                <h1>{klayPrice} KLAY</h1>
              </PriceWrap>

              <DetailBtnWrap>
                <Link
                  to={`/packs/${currentTab.toLowerCase()}`}
                  style={{ textDecoration: 'none', color: 'white' }}
                >
                  {t('mintselect.detailbtn')}
                </Link>
              </DetailBtnWrap>
            </LeftWrap>
            <RightWrap>
              <MintImg src='/image/character.jpg' />
            </RightWrap>
          </NftWrap>
        )}
        {currentTab === 'STORY' && (
          <NftWrap>
            <LeftWrap>
              <div>
                <h1 className='title'>
                  {t(`packinfos.${currentTab.toLowerCase()}.selecttitle`)}
                  <span> NFT MINTING</span>
                </h1>
              </div>
              <h2>
                {t(`packinfos.${currentTab.toLowerCase()}.selectdescription`)}
              </h2>
              <PriceWrap>
                <h1>{klayPrice} KLAY</h1>
              </PriceWrap>

              <DetailBtnWrap>
                <Link
                  to={`/packs/${currentTab.toLowerCase()}`}
                  style={{ textDecoration: 'none', color: 'white' }}
                >
                  {t('mintselect.detailbtn')}
                </Link>
              </DetailBtnWrap>
            </LeftWrap>
            <RightWrap>
              <MintImg src='/image/story.jpg' />
            </RightWrap>
          </NftWrap>
        )}
      </MintSelectWrap>
      <Footer />
    </>
  );
};

export default MintPage;

const TabWrap = styled.div`
  width: 1280px;
  background-color: #04080e;
  margin: 0 auto;
  border-radius: 15px;
  /* margin-top: 60px; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
`;

interface ITabBtn {
  currentTab?: string;
  item?: string;
}

const TabBtn = styled(Button)<ITabBtn>`
  cursor: pointer;
`;

const TabTitle = styled.h1<ITabBtn>`
  &:hover {
    color: #1fc7d4 !important;
  }
  font-family: GyeonggiBatang;
  color: ${({ currentTab, item }) =>
    currentTab === item ? '#1fc7d4' : 'white'};
`;

const MintImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 15px;
`;

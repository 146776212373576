import Web3 from "web3";

// Web 3 connection
const web3: Web3 = new Web3(Web3.givenProvider);

export const web3Direct = new Web3(
  new Web3.providers.HttpProvider(process.env.REACT_APP_RPC_URL as string)
);

export default web3;

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface IPacksInfoProps {
  name: 'story' | 'character';
}

const PacksInfo: React.FunctionComponent<IPacksInfoProps> = ({ name }) => {
  const { t } = useTranslation();

  return (
    <FigureInfoWrap>
      <Title>{t(`packinfos.${name}.title`)}</Title>
      <InfoData>
        <h1>{t(`packinfos.${name}.description.title`)}</h1>
      </InfoData>
      <FigureDescription>
        {t(`packinfos.${name}.description.description`)}
      </FigureDescription>
    </FigureInfoWrap>
  );
};

export default PacksInfo;

const FigureInfoWrap = styled.div`
  margin-top: 40px;
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  max-width: 620px;
  width: 100%;
  height: 70px;
  border-radius: 8px;
  background-color: rgb(25, 33, 71);
  color: rgb(233, 240, 254);
  font-size: 1.125rem;
  line-height: 1.40625rem;
  font-weight: bold;
  font-size: 30px;
`;

const InfoData = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  h1 {
    color: white;
  }
`;

const FigureDescription = styled.div`
  margin-top: 20px;
  line-height: 1.8;
  padding: 10px;
  font-size: 20px;
  white-space: pre-line;
  text-align: center;
`;

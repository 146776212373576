import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import { provider } from "web3-core";

const { persistAtom } = recoilPersist();

export interface IWalletAccount {
  chainId: 1 | 3 | 4 | 5 | 42 | 56 | 97 | 1001 | 1337 | 8217 | number;
  wallet: string;
  account: string;
  ethereum?: provider;
  token?: string;
}

const walletAccountAtom = atom<IWalletAccount>({
  key: "walletAccount",
  default: {
    chainId: 1,
    wallet: "",
    account: "",
  },
  effects_UNSTABLE: [persistAtom],
});

export default walletAccountAtom;

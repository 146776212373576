import * as React from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import PacksBuy from './PacksBuy';
import PacksLevel from './PacksLevel';
import PacksDesc from './PacksLevel';
import PacksInfo from './PacksInfo';
import { CircularProgress } from '@mui/material';
import MintingModal from '../Modal/MintingModal';
import { useTranslation } from 'react-i18next';

interface IPacksDetailProps {
  name: 'story' | 'character';
}

const PacksDetail: React.FunctionComponent<IPacksDetailProps> = ({ name }) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const { t } = useTranslation();

  return (
    <>
      <App>
        <Wrap>
          <div>
            <MintImg src={`/image/${name}.jpg`} alt='story' />
          </div>
          <PacksBuy name={name} setIsLoading={setIsLoading} />
          <PacksInfo name={name} />
          <PacksLevel name={name} />
        </Wrap>
        <EventDescription>
          <h1>{t('packinfos.story.buy.infomessage1')}</h1>
          <div>{t('packinfos.story.buy.infomessage2')}</div>
        </EventDescription>
      </App>
      {isLoading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 100000,
          }}
        >
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default PacksDetail;

const MintImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 15px;
`;

const App = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  color: white;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span {
    margin: 0;
  }
`;

const Wrap = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  background-color: #04080e;
  border-radius: 15px;
  padding: 80px 0 80px 40px;
  & > div {
    width: 50%;
    aspect-ratio: 1;
    position: relative;
    &:first-child {
      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
  }
  /* @media (max-width: 1400px) {
    padding: 40px 30px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    & > div {
      width: 100%;
    }
  } */
`;

const EventDescription = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  text-align: center;
  background-color: #1a2147;
  padding: 20px;
  border-radius: 15px;
  h1 {
    font-size: 25px;
    color: #04dde9;
    font-weight: bold;
    white-space: pre-line;
    line-height: 1.5;
    margin-top: 10px;
  }
  div {
    margin-top: 20px;
    font-size: 20px;
    white-space: pre-line;
    line-height: 1.5;
    margin-bottom: 10px;
  }
  p {
    line-height: 1.5;
  }
`;

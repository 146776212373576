import React from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import {
  NftInfoWrap,
  LogoWrap,
  TitleWrap,
  SubTitle,
  DetailWrap,
  ImgWrap,
  LeftTitle,
  DescriptionWrap,
  IdentWrap,
} from './styles';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

const NftInfo: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <NftInfoWrap>
        <LogoWrap>
          <img src="/image/intro-logo.png" />
        </LogoWrap>
        <TitleWrap>{t('nftinfo.title')}</TitleWrap>
        <SubTitle style={{ marginTop: 170 }}>{t('nftinfo.subtitle1')}</SubTitle>
        <DetailWrap>
          <p>
            {t('nftinfo.nft')} <span>{t('nftinfo.nftbold')}</span>
            {t('nftinfo.nft2')} <span>{t('nftinfo.nftbold2')}</span>{' '}
            {t('nftinfo.nft3')}
            <span>{i18n.language === 'en' ? t('nftinfo.nftbold3') : ''}</span>
            {i18n.language === 'en' ? t('nftinfo.nft4') : ''}
          </p>
        </DetailWrap>
        <SubTitle style={{ marginTop: 170 }}>{t('nftinfo.subtitle2')}</SubTitle>
        <ImgWrap>
          <img src="/image/nftinfo-figure.png" />
        </ImgWrap>
        <DetailWrap>
          <p>{t('nftinfo.figure')}</p>
        </DetailWrap>
        <LeftTitle>{t('nftinfo.subtitle3')}</LeftTitle>
        <DescriptionWrap>
          <p>
            {t('nftinfo.description.figure.number1')}
            <span>{t('nftinfo.description.figure.number1bold')}</span>
            {t('nftinfo.description.figure.number1-2')}
            <IdentWrap>
              {i18n.language === 'en'
                ? t('nftinfo.description.figure.number1-3')
                : ''}
            </IdentWrap>
            {t('nftinfo.description.figure.number2')}
            {i18n.language === 'en' ? (
              <IdentWrap>
                {t('nftinfo.description.figure.number2-1')}
                <span>{t('nftinfo.description.figure.number2-1bold')}</span>
                {t('nftinfo.description.figure.number2-1-1')}
              </IdentWrap>
            ) : (
              <></>
            )}
            <span>{t('nftinfo.description.figure.number2bold')}</span>
            {t('nftinfo.description.figure.number2-2')}
            <IdentWrap>{t('nftinfo.description.figure.number2-3')}</IdentWrap>
            {t('nftinfo.description.figure.number3')}
            <span>{t('nftinfo.description.figure.number3bold')}</span>
            {t('nftinfo.description.figure.number3-2')}
            <IdentWrap>
              <span>{t('nftinfo.description.figure.number3bold-2')}</span>
              {t('nftinfo.description.figure.number3-3')}
            </IdentWrap>
            <IdentWrap>
              {t('nftinfo.description.figure.number3-4')}
              <span>{t('nftinfo.description.figure.number3bold-3')}</span>{' '}
              {t('nftinfo.description.figure.number3-5')}
            </IdentWrap>
            {t('nftinfo.description.figure.number4')}
            <span>{t('nftinfo.description.figure.number4bold')}</span>
            {t('nftinfo.description.figure.number4-2')}
            <IdentWrap>{t('nftinfo.description.figure.number4-3')}</IdentWrap>
            {i18n.language === 'en' ? (
              <IdentWrap>{t('nftinfo.description.figure.number4-4')}</IdentWrap>
            ) : (
              <></>
            )}
            {t('nftinfo.description.figure.number5')}{' '}
            <span>{t('nftinfo.description.figure.number5bold')}</span>{' '}
            {t('nftinfo.description.figure.number5-2')}
            {t('nftinfo.description.figure.number6')}
            <span>{t('nftinfo.description.figure.number6bold')}</span>
            {t('nftinfo.description.figure.number6-2')}
            <IdentWrap>{t('nftinfo.description.figure.number6-3')}</IdentWrap>
          </p>
        </DescriptionWrap>
        <SubTitle style={{ marginTop: 170 }}>{t('nftinfo.subtitle4')}</SubTitle>
        <ImgWrap>
          <img src="/image/nftinfo-chracter.png" />
        </ImgWrap>
        <DetailWrap>
          <p>{t('nftinfo.chracter')}</p>
        </DetailWrap>
        <LeftTitle>{t('nftinfo.subtitle5')}</LeftTitle>
        <DescriptionWrap>
          <p>
            {t('nftinfo.description.chracter.number1')}
            <span>{t('nftinfo.description.chracter.number1bold')}</span>
            {t('nftinfo.description.chracter.number1-2')}
            {i18n.language === 'en' ? (
              <IdentWrap>
                {' '}
                {t('nftinfo.description.chracter.number1-3')}
              </IdentWrap>
            ) : (
              <></>
            )}
            {t('nftinfo.description.chracter.number2')}
            <span>{t('taocoin')}</span>
            {t('nftinfo.description.chracter.number2-1-1')}
            {i18n.language === 'en' ? (
              <IdentWrap>
                {t('nftinfo.description.chracter.number2-1')}
              </IdentWrap>
            ) : (
              <></>
            )}
            <IdentWrap>
              {t('nftinfo.description.chracter.number2-2-1')}
              <span>{t('taocoin')}</span>
              {t('nftinfo.description.chracter.number2-2')}
            </IdentWrap>
            <IdentWrap>{t('nftinfo.description.chracter.number2-3')}</IdentWrap>
            {t('nftinfo.description.chracter.number3')}
            <span>{t('nftinfo.description.chracter.number3bold')}</span>
            {t('nftinfo.description.chracter.number3-2')}
            <span>{t('nftinfo.description.chracter.number3bold-2')}</span>
            {t('nftinfo.description.chracter.number3-3')}
            <IdentWrap>{t('nftinfo.description.chracter.number3-4')}</IdentWrap>
          </p>
        </DescriptionWrap>
        <SubTitle style={{ marginTop: 170 }}>{t('nftinfo.subtitle6')}</SubTitle>
        <ImgWrap>
          <img src="/image/nftinfo-story.png" />
        </ImgWrap>
        <DetailWrap>
          <p>{t('nftinfo.story')}</p>
        </DetailWrap>
        <LeftTitle>{t('nftinfo.subtitle7')}</LeftTitle>
        <DescriptionWrap style={{ marginBottom: 180 }}>
          <p>
            {t('nftinfo.description.story.number1')}
            <span>{t('nftinfo.description.story.number1bold')}</span>
            {t('nftinfo.description.story.number1-2')}
            {t('nftinfo.description.story.number2')}
            <span>{t('nftinfo.description.story.number2bold')}</span>
            {t('nftinfo.description.story.number2-2')}
            {t('nftinfo.description.story.number3')}
            {i18n.language === 'en' ? (
              <IdentWrap>{t('nftinfo.description.story.number3-1')}</IdentWrap>
            ) : (
              <></>
            )}
            {t('nftinfo.description.story.number4')}
            <span>{t('taocoin')}</span>
            {t('nftinfo.description.story.number4-1-1')}
            {i18n.language === 'en' ? (
              <IdentWrap>{t('nftinfo.description.story.number4-1')}</IdentWrap>
            ) : (
              <></>
            )}
            {t('nftinfo.description.story.number5')}
            <span>{t('nftinfo.description.story.number5bold')}</span>
            {t('nftinfo.description.story.number5-2')}
            <span>{t('taocoin')}</span>
            <IdentWrap>{t('nftinfo.description.story.number5-3')}</IdentWrap>
          </p>
        </DescriptionWrap>
      </NftInfoWrap>
      <Footer />
    </>
  );
};

export default NftInfo;

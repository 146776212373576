import moment from 'moment';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useTimer } from 'react-timer-hook';
import styled from 'styled-components';

interface ICountdownProps {}

const date = {
  erika: '2022-03-02 15:00:00',
  bomi: '2022-03-03 15:00:00',
  ayu: '2022-03-04 15:00:00',
  adela: '2022-03-09 15:00:00',
  chloe: '2022-03-10 15:00:00',
  aida: '2022-03-11 15:00:00',
};

const Countdown: React.FunctionComponent<ICountdownProps> = () => {
  const { name } = useParams() as {
    name: 'erika' | 'ayu' | 'bomi' | 'adela' | 'chloe' | 'aida';
  };

  const { days, hours, minutes, seconds } = useTimer({
    expiryTimestamp: moment(
      moment(`${date[name]}`, 'YYYY-MM-DD HH:mm:ss')
    ).toDate(),
  });

  return (
    <CountdownWrap>
      <div className="item-wrap">
        <div className="item">{days}</div>
        <div className="title">DAYS</div>
      </div>
      <div className="item-wrap">
        <div className="item">{hours < 10 ? `0${hours}` : hours}</div>
        <div className="title">HOURS</div>
      </div>
      <div className="item-wrap">
        <div className="item">{minutes < 10 ? `0${minutes}` : minutes}</div>
        <div className="title">MINUTES</div>
      </div>
      <div className="item-wrap">
        <div className="item">{seconds < 10 ? `0${seconds}` : seconds}</div>
        <div className="title">SECONDS</div>
      </div>
    </CountdownWrap>
  );
};

const CountdownWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20px 0;
  .item-wrap {
    display: flex;
    flex-direction: column;
    width: 100px;
    .item {
      background-color: white;
      height: 100px;
      line-height: 100px;
      margin-bottom: 5px;
      border-radius: 10px;
      text-align: center;
      font-size: 60px;
    }
    .title {
      color: white;
      font-weight: bold;
      text-align: center;
    }
  }
`;

export default Countdown;

import React from 'react';

import {
  IntroWrap,
  LogoWrap,
  StoryDetail,
  SubTitle,
  TitleWrap,
  PlayDetail,
  CardImgWrap,
  CardDetail,
  CollectImgWrap,
  CollectDetail,
  StoryImgWrap,
  EarnImgWrap,
  EarnDetail,
  NftWrap,
  NftDetail,
  StoryModeDetail,
  RightWrap,
} from './styles';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

const GameInfo: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <IntroWrap>
        <LogoWrap>
          <img src="/image/intro-logo.png" />
        </LogoWrap>
        <TitleWrap>{t('gameinfo.title')}</TitleWrap>
        <SubTitle style={{ marginTop: 120 }}>STORY</SubTitle>
        <StoryDetail>
          <p dangerouslySetInnerHTML={{ __html: t('gameinfo.story') }} />
        </StoryDetail>
        <SubTitle style={{ marginTop: 220 }}>PLAY</SubTitle>
        <PlayDetail>
          <img src="/image/play.png" />
          <p dangerouslySetInnerHTML={{ __html: t('gameinfo.play') }} />
        </PlayDetail>
        <SubTitle style={{ marginTop: 240 }}>DESIGN</SubTitle>
        <CardImgWrap>
          <img src="/image/intro-card.png" />
        </CardImgWrap>
        <CardDetail>
          <p dangerouslySetInnerHTML={{ __html: t('gameinfo.design') }} />
        </CardDetail>
        <SubTitle style={{ marginTop: 220 }}>COLLECT</SubTitle>
        <CollectImgWrap>
          <img src="/image/collect.png" />
        </CollectImgWrap>
        <CollectDetail>
          <p dangerouslySetInnerHTML={{ __html: t('gameinfo.collect') }} />
          <RightWrap>
            {i18n.language === 'en' ? t('gameinfo.collect2') : ''}
          </RightWrap>
        </CollectDetail>
        <SubTitle style={{ marginTop: 220 }}>STORY MODE</SubTitle>
        <StoryImgWrap>
          <img src="/image/story-mode.png" />
        </StoryImgWrap>
        <StoryModeDetail>
          <p dangerouslySetInnerHTML={{ __html: t('gameinfo.storymode') }} />
        </StoryModeDetail>
        <SubTitle style={{ marginTop: 220 }}>EARN</SubTitle>
        <EarnImgWrap>
          <img src="/image/earn.png" />
        </EarnImgWrap>
        <EarnDetail>
          <p dangerouslySetInnerHTML={{ __html: t('gameinfo.earn') }} />
        </EarnDetail>
      </IntroWrap>
      <Footer />
    </>
  );
};

export default GameInfo;

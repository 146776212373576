import React from 'react';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import MoveTopBtn from '../../../components/WebtoonComponents/TopBtn';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link } from 'react-router-dom';
import { Button, CircularProgress } from '@mui/material';
import { isIncludeWebtoonPageWL } from '.';
import { getWebtoonEpisode, getWebtoonList } from '../../../utils/api';
import { IWebtoonLinkInfo } from '../../../types';

interface IWebtoonDetailProps {
  name: string;
}

const WebtoonDetail: React.FunctionComponent<IWebtoonDetailProps> = ({
  name,
}) => {
  const navigate = useNavigate();

  const goBack = React.useCallback(() => {
    navigate(`/webtoon/${Number(name) - 1}`);
    setWebtoonTitle('');
    setWebtoonLinkInfos([]);
  }, [name]);
  const goNext = React.useCallback(() => {
    navigate(`/webtoon/${Number(name) + 1}`);
    setWebtoonTitle('');
    setWebtoonLinkInfos([]);
  }, [name]);

  const [webtoonLinkInfos, setWebtoonLinkInfos] = React.useState<
    IWebtoonLinkInfo[]
  >([]);
  const [webtoonTitle, setWebtoonTitle] = React.useState<string>('');

  const getWebtoonData = React.useCallback(async () => {
    try {
      const list = await getWebtoonList();
      const result = await getWebtoonEpisode(
        list.result[Number(name) - 1].title
      );

      setWebtoonTitle(list.result[Number(name) - 1].title);
      setWebtoonLinkInfos(result.linkInfo);
    } catch (e: any) {
      if (e.code === 409) {
        getWebtoonData();
      }
    }
  }, [name]);

  React.useEffect(() => {
    getWebtoonData();
  }, [name]);

  return (
    <DetailWrap>
      <WebtoonWrap>
        <h1>
          {name}화 {webtoonTitle}
        </h1>
        {webtoonLinkInfos.map((item, i: number) => (
          <LazyLoadImage
            key={i}
            // src={`/image/webtoon/prologue_0${i + 1}.jpg`}
            src={item.link}
            alt="webtoon"
          />
        ))}
        <BtnWrap>
          <PrevBtn
            disabled={!isIncludeWebtoonPageWL((parseInt(name) - 1).toString())}
            onClick={goBack}
          >
            <ArrowBackIosNewIcon fontSize="large" />
          </PrevBtn>
          <ListBtn to="/webtoon">목록보기</ListBtn>

          <NextBtn
            disabled={!isIncludeWebtoonPageWL((parseInt(name) + 1).toString())}
            onClick={goNext}
          >
            <ArrowForwardIosIcon fontSize="large" />
          </NextBtn>
        </BtnWrap>
      </WebtoonWrap>
      {/* <TopBtn isActive={isScroll} fontSize="large" onClick={onTopBtnClick} /> */}

      <MoveTopBtn />
    </DetailWrap>
  );
};

// `${Number(name) + 1}`

export default WebtoonDetail;

const DetailWrap = styled.div`
  min-width: 1920px;
  margin: 0 auto;
  padding: 100px 0;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
`;

const WebtoonWrap = styled.div`
  width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    color: white;
    font-size: 50px;
    font-weight: bold;
  }
  img {
    width: 100%;
  }
`;

const BtnWrap = styled.div`
  margin-top: 50px;
  display: flex;
  align-items: center;
  gap: 30px;
`;

const ListBtn = styled(Link)`
  background-color: #66e6b8;
  width: 150px;
  height: 50px;
  padding: 10px 20px;
  color: black;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  border-radius: 10px;
  :hover {
    opacity: 0.7;
  }
`;

const PrevBtn = styled(Button)`
  color: #66e6b8;
  cursor: pointer;
`;

const NextBtn = styled(Button)`
  color: #66e6b8;
  cursor: pointer;
`;

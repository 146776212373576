import React from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useRecoilState } from 'recoil';

import { CommonCard, MypageWrap, Notice } from './styles';
import walletAtom from '../../atoms/wallet';
import MyNftWrap from '../../components/MyPages/MyNftWrap';
import Storage from '../../components/MyPages/Storage';
import walletAccountAtom from '../../atoms/walletAccount';
import { useWeb3React } from '@web3-react/core';
import { deeplink } from '../../constants';
import { injectedConnector } from '../../connector';
import { changeNetwork } from '../../utils';
import { useNavigate } from 'react-router-dom';
import WalletCheck from '../../components/Modal/WalletCheck';
import ChargeWrap from '../../components/MyPages/ChargeWrap';
import { Box, Modal, ModalProps } from '@mui/material';
import { CloseBtn } from '../Home/styles';
import { useTranslation } from 'react-i18next';
import { putCheckBurn } from '../../utils/api';
import web3 from '../../connection/web3';
import { useInterval } from 'usehooks-ts';

interface IMyPageProps extends ModalProps {
  children: React.ReactElement;
}

const Mypage: React.FunctionComponent<IMyPageProps> = (props) => {
  const { t } = useTranslation();

  const [walletAccount, setWalletAccount] = useRecoilState(walletAccountAtom);
  const [error, setError] = React.useState<string | null>(null);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [isWalletCheckOpen, setIsWalletCheckOpen] =
    React.useState<boolean>(false);

  const handleWalletCheckClose = React.useCallback(() => {
    setIsWalletCheckOpen(false);
    navigate('/', { replace: true });
  }, [isWalletCheckOpen, setIsWalletCheckOpen]);

  const web3Start = useWeb3React();

  const navigate = useNavigate();

  const handleClose = React.useCallback(() => setIsOpen(false), []);

  // const web3Login = async () => {
  //   await web3Start.activate(injectedConnector, async (error) => {
  //     console.log(error.name);
  //     console.log(error.message);

  //     if (
  //       error.name === 'UnsupportedChainIdError' ||
  //       error.name === 'e' ||
  //       error.message.indexOf('Unsupported chain id') !== -1
  //     ) {
  //       //위 에러일땐 클레이튼 네트워크로 변경
  //       await changeNetwork({ networkName: 'klaytn', setError });
  //     } else if (
  //       error.name === 'NoEthereumProviderError' ||
  //       error.name === 't'
  //     ) {
  //       // 모바일일땐 deeplink 전달 deeplink ("https://metamask.app.link/dapp/www.meta-soul.kr/")
  //       window.open(deeplink);
  //     }
  //   });

  //   if (web3Start.account && web3Start.chainId) {
  //     //web3Start.connector 초기값은 undefined injectedConnector은 연결 되었을때 나타남 연결이 되었다면 localStorage, recoil에 wallet 정보를 저장한다.
  //     if (web3Start.connector === injectedConnector) {
  //       window.localStorage.setItem('wallet', 'metamask');

  //       setWalletAccount((prev) => ({
  //         ...prev,
  //         chainId: web3Start.chainId!,
  //         wallet: 'metamask',
  //         account: web3Start.account!,
  //       }));
  //     }
  //   }
  // };

  // useInterval(() => {
  //   getGas();
  // }, 5000);

  React.useEffect(() => {
    if (!window.localStorage.getItem('wallet')) {
      //페이지 렌더링시 web3Start.account 가 없고, 현재 로컬스토리지에 'wallet'이 없다면 지갑연결 실행
      setIsWalletCheckOpen(true);
    }
    setIsOpen(true);
    // getGas();
  }, []);

  return (
    <>
      <Header />
      <MypageWrap>
        {/* <MyPageTitle>MyPage</MyPageTitle>
        <MyPageDescription>나의 정보를 확인하세요</MyPageDescription> */}
        {/* <ChargeWrap /> */}
        <MyNftWrap />
        <Storage />
      </MypageWrap>
      <Footer />
      {/* @ts-ignore */}
      <WalletCheck open={isWalletCheckOpen} onClose={handleWalletCheckClose} />
      <Modal open={isOpen} onClose={handleClose}>
        <Box sx={Notice}>
          <CommonCard>
            <CloseBtn onClick={handleClose}>
              <img src='/image/closegray.svg' />
            </CloseBtn>
            <h2>{t('mypagenotice')}</h2>
            <img
              src='/image/mypage-notice.jpg'
              alt='notice'
              style={{ height: 600 }}
            />
          </CommonCard>
        </Box>
      </Modal>
    </>
  );
};

export default React.memo(Mypage);

import web3, { web3Direct } from '../connection/web3';
import Collection from '../abis/MetaSoul.json';
import ItemCollection from '../abis/MSINT.json';
import { address, itemaddress } from './address';

//@ts-ignore
export const contract = new web3.eth.Contract(
  //@ts-ignore
  Collection.abi,
  address
);

//@ts-ignore
export const contractDirect = new web3Direct.eth.Contract(
  //@ts-ignore
  Collection.abi,
  address
);

//@ts-ignore
export const itemContract = new web3.eth.Contract(
  //@ts-ignore
  ItemCollection.abi,
  itemaddress
);

import { SetStateAction, Dispatch } from 'react';
import { networks } from '../constants/networks';

const getShortAddress = (address: string) => {
  return `${address.substr(0, 5)}...${address.substr(address.length - 5)}`;
};

const zeroAddress = '0x0000000000000000000000000000000000000000';

const changeNetwork = async ({
  networkName,
  setError,
}: {
  networkName: string;
  setError: SetStateAction<Dispatch<string>>;
}) => {
  try {
    // @ts-ignore
    if (!window.ethereum) {
      throw new Error('No crypto wallet found');
    }

    // @ts-ignore
    await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [
        // @ts-ignore
        { ...networks[networkName] },
      ],
    });
  } catch (e: any) {
    setError(e.message);
  }
};

const getTokenPrice = async () => {
  try {
    const data = await fetch(
      new Request(
        `https://api.coingecko.com/api/v3/simple/price?ids=klay-token&vs_currencies=krw`
      ),
      {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
        }),
      }
    ).then(async (res) => {
      return await res.json();
    });

    return data;
  } catch (_) {
    return 0;
  }
};

export { getShortAddress, zeroAddress, changeNetwork, getTokenPrice };

import { Box, Modal } from "@mui/material";
import React from "react";
import ReactPlayer, { ReactPlayerProps } from "react-player";

// ReactPlayer의 타입 가져와서 컴포넌트 타입에 넣어줌
interface IPlayerItem extends ReactPlayerProps {}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 600,
  outline: "none",
};

const PlayerItem: React.FC<IPlayerItem> = (props) => {
  // Modal 관련 state
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  // Modal open, close 함수
  const handleOpen = React.useCallback(() => setIsOpen(true), []);
  const handleClose = React.useCallback(() => setIsOpen(false), []);

  return (
    <>
      {/* 클릭 이벤트 */}
      <div onClick={handleOpen}>
        {/* props로 받아온 데이터 넘겨주기 */}
        <ReactPlayer {...props} />
      </div>
      <Modal open={isOpen} onClose={handleClose}>
        <Box sx={style}>
          <ReactPlayer
            url={props.url}
            playing
            muted
            loop
            width="100%"
            height="100%"
          />
        </Box>
      </Modal>
    </>
  );
};

export default React.memo(PlayerItem);

import { Button } from '@mui/material';
import styled from 'styled-components';

export const MypageWrap = styled.div`
  min-width: 1920px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 100px 0;
  background-color: black;
  color: white;
`;

export const MyPageTitle = styled.h1`
  text-align: center;
`;

export const MyPageDescription = styled.h2`
  text-align: center;
`;

export const ProfileWrap = styled.div`
  width: 1280px;
  background-color: #04080e;
  margin: 0 auto;
  margin-top: 40px;
  display: flex;
  border-radius: 15px;
  padding: 15px;
`;

export const ProfileImageWrap = styled.div`
  img {
    max-width: 180px;
    border-radius: 50%;
    margin: 0px 60px 0px 16px;
  }
`;

export const ProfileInfoWrap = styled.div`
  width: 100%;
`;

export const NameWrap = styled.div`
  margin-bottom: 27px;
  display: flex;
  align-items: center;
`;

export const Name = styled.h2`
  color: white;
  margin-right: 50px;
`;

export const InviteBtn = styled.button`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  min-width: 180px;
  width: 95%;
  height: 60px;
  margin: 0px;
  background-color: rgb(91, 21, 213);
  border: 0px;
  border-radius: 8px;
  color: white;
  font-size: 1.125rem;
  line-height: 1.40625rem;
  outline: none;
  cursor: pointer;
  box-sizing: border-box;
`;

export const CustomerWrap = styled.div`
  min-width: 80px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid rgb(233, 240, 254);
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    color: white;
    font-weight: bold;
  }
`;

export const CardImage = styled.img``;

export const Notice = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  outline: 'none',
};

export const CommonCard = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 500px;
  padding: 20px;
  border-radius: 16px;
  box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px 0px;
  background-color: #04080e;
  box-sizing: border-box;
  text-align: center;
  align-items: center;
  h2 {
    color: white;
  }
`;

export const CloseBtn = styled(Button)`
  /* padding-left: 96%; */
  margin-left: 90% !important;
`;

import React from 'react';
import ReactDOM from 'react-dom';
// import "./index.css";
import App from './App';
import './i18n';
// import Reset from "./styles/Reset";
import { BrowserRouter } from 'react-router-dom';
import GlobalStyle from './styles/Globalstyles';

ReactDOM.render(
  <React.StrictMode>
    {/* <Reset /> */}
    <BrowserRouter>
      <App />
    </BrowserRouter>
    <GlobalStyle />
  </React.StrictMode>,
  document.getElementById('root')
);

import styled from 'styled-components';

export const NftInfoWrap = styled.div`
  min-width: 1920px;

  margin: 0 auto;
  background-image: url('/image/nftinfobg.jpg');
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100;
  background-color: #000101;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: GyeonggiBatang;
  -webkit-text-size-adjust: none; /*Chrome, Safari, newer versions of Opera*/
  -moz-text-size-adjust: none; /*Firefox*/
  -ms-text-size-adjust: none; /*Ie*/
  -o-text-size-adjust: none; /*old versions of Opera*/
`;

export const LogoWrap = styled.div`
  margin-top: 130px;
  img {
    width: 890px;
    height: 171px;
  }
`;
export const TitleWrap = styled.h1`
  margin-top: 40px;
  font-size: 35px;
  font-weight: bold;
  font-style: oblique;
  color: white;
  white-space: nowrap;
`;

export const SubTitle = styled.div`
  font-size: 30px;
  color: white;
  white-space: nowrap;
  font-weight: bold;
`;

export const NftDetail = styled.div`
  margin-top: 40px;
  width: 1100px;
  white-space: pre-line;
  display: flex;
  justify-content: center;
  text-align: center;
  line-height: 1.5;
  font-size: 20px;
  p {
    color: white;
  }
  span {
    color: #ffe27e;
    font-weight: bold;
  }
`;

export const DetailWrap = styled.div`
  margin-top: 20px;
  /* width: 1100px; */
  white-space: pre-line;
  display: flex;
  justify-content: center;
  text-align: center;
  line-height: 1.5;
  font-size: 25px;
  p {
    color: white;
  }
  span {
    color: #ffe27e;
    font-weight: bold;
  }
`;

export const ImgWrap = styled.div`
  img {
    margin-top: 50px;
    width: 1200px;
  }
`;

export const LeftTitle = styled.div`
  margin-top: 40px;
  font-size: 30px;
  width: 1100px;
  display: flex;
  justify-content: flex-start;
  color: white;
  font-weight: bold;
`;

export const DescriptionWrap = styled.div`
  margin-top: 20px;
  width: 1200px;
  padding-left: 70px;
  font-size: 25px;
  white-space: pre-line;
  line-height: 1.5;
  padding-right: 100px;
  p {
    color: white;
  }
  span {
    color: #ffe27e;
    font-weight: bold;
  }
`;

export const IdentWrap = styled.div`
  text-indent: 25px;
`;

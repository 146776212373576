import React, { useEffect, useState } from 'react';

import Video from '../../components/Video';
import SwiperWrapper from '../../components/Swiper';
import PopUp from '../../components/Popup';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import {
  BodyWrapper,
  BodyIntroWrapper,
  SloganWrapper,
  BodyContentsWrapper,
  MainLogo,
  NftWrapper,
  Slogan,
  Nft,
  NftBold,
  CardWrapper,
  CartImage,
  VideoWrapper,
  Barcode,
  MintingWrapper,
  Minting,
  MintingDetailWrapper,
  MintingDetail,
  MintingBold,
  FigureFrame,
  BodyGridWrapper,
  GridContentsWrapper,
  GridTitleWrapper,
  GridTitle,
  GridTitleSmall,
  Flex,
  BodyEmailWrapper,
  EmailContentsWrapper,
  EmailTitleWrapper,
  EmailTitle,
  EmailTitle2,
  SubmitBtnWrapper,
  SubmitBtn,
  Divider3,
  Divider1,
  Divider2,
  style,
  PopUpStyle,
  GridCardWrapper,
  GridCard,
  CardTitleWrapper,
  CardTitle,
  CardDetail,
  ModalMiddleWrapper,
  CloseBtn,
  MintLink,
  BodyTeamWrapper,
  TeamContentsWrapper,
  GoogleWrap,
  IntroBgWrap,
  MainLogoWrap,
} from './styles';
import { useTranslation } from 'react-i18next';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Team from '../../components/Team';
import { Button } from '@mui/material';

const HomePage: React.FC = () => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [check, setCheck] = useState(false);

  const handleOpen = React.useCallback(() => setOpen(!open), []);
  const handleClose = React.useCallback(() => setOpen(false), []);

  const onClickSubmit = React.useCallback(() => {
    window.open('https://page.stibee.com/subscriptions/166902', '_blank');
  }, []);

  const onClickDownload = React.useCallback(() => {
    window.open(
      'https://play.google.com/store/apps/details?id=com.taonplay.MetaSoul'
    );
  }, []);

  return (
    <>
      <Header />

      <BodyWrapper>
        <BodyIntroWrapper>
          <BodyContentsWrapper>
            <IntroBgWrap>
              <SloganWrapper>
                <Slogan>{t('bodyintro.slogan1')}</Slogan>
              </SloganWrapper>
              <MainLogoWrap>
                <MainLogo src='/image/mainlogo.png' />
              </MainLogoWrap>
              <NftWrapper>
                <Nft
                  dangerouslySetInnerHTML={{
                    __html: t('bodyintro.nftdescription'),
                  }}
                />
              </NftWrapper>
            </IntroBgWrap>
            <CardWrapper>
              <CartImage src='/image/card.png' />
              <GoogleWrap onClick={onClickDownload}>
                <img src='/image/google.png' alt='Google' />
              </GoogleWrap>
            </CardWrapper>

            <VideoWrapper onClick={handleOpen}>
              <Video />
            </VideoWrapper>
            <Divider1 src='/image/divider.png' />
            <Barcode src='/image/barcode.png' />
            <MintingWrapper>
              <Minting>{t('bodyintro.nftmintingtitle')}</Minting>
            </MintingWrapper>
            <MintingDetailWrapper>
              <MintingDetail>
                {t('bodyintro.nftminting.description1')}
                <MintingBold>
                  {t('bodyintro.nftminting.descriptionbold')}
                </MintingBold>
                {t('bodyintro.nftminting.description2')}
                <br />
                {t('bodyintro.nftminting.description3')}
                <MintingBold>
                  {t('bodyintro.nftminting.descriptionbold1')}
                </MintingBold>
                {t('bodyintro.nftminting.description4')}
              </MintingDetail>
            </MintingDetailWrapper>
            <FigureFrame>
              <SwiperWrapper />
            </FigureFrame>
            <Divider2 src='/image/divider2.png' />
            {/* @ts-ignore */}
            <MintLink to='/mint'>{t('mintbtn')}</MintLink>
          </BodyContentsWrapper>
        </BodyIntroWrapper>
        <BodyGridWrapper>
          <GridContentsWrapper>
            <GridTitleWrapper>
              <GridTitle>
                {t('bodygrid.gridtitle.title1')}
                <GridTitleSmall>
                  {t('bodygrid.gridtitle.title2')}
                </GridTitleSmall>{' '}
                {t('bodygrid.gridtitle.title3')}
              </GridTitle>
            </GridTitleWrapper>
            <Flex>
              {(
                t('bodygrid.gridCards', { returnObjects: true }) as Array<{
                  title: string;
                  content: string;
                }>
              ).map(({ title, content }, i: number) => {
                return (
                  <GridCardWrapper
                    key={i}
                    style={{
                      opacity:
                        title === '공개예정' || title === 'Coming Soon'
                          ? 0.5
                          : 1,
                    }}
                  >
                    <GridCard>
                      <CardTitleWrapper>
                        <CardTitle>{title}</CardTitle>
                      </CardTitleWrapper>
                      <CardDetail>{content}</CardDetail>
                    </GridCard>
                  </GridCardWrapper>
                );
              })}
            </Flex>
            <Divider3 src='/image/divider3.png' />
          </GridContentsWrapper>
        </BodyGridWrapper>

        <Team />

        {/* <BodyEmailWrapper>
          <EmailContentsWrapper>
            <EmailTitleWrapper>
              <EmailTitle>{t('email.emailtitle.title1')}</EmailTitle>
            </EmailTitleWrapper>
            <EmailTitle2>{t('email.emailtitle.title2')}</EmailTitle2>

            <a id="submit">
              <SubmitBtnWrapper>
                <SubmitBtn onClick={onClickSubmit}>
                  {t('email.submitbtn')}
                </SubmitBtn>
              </SubmitBtnWrapper>
            </a>
          </EmailContentsWrapper>
        </BodyEmailWrapper> */}
      </BodyWrapper>

      <Footer />
      <Modal open={open} onClose={handleClose} onBackdropClick={handleClose}>
        <Box sx={style}>
          <ModalMiddleWrapper>
            <Video open={open} />
          </ModalMiddleWrapper>
        </Box>
      </Modal>
    </>
  );
};

export default HomePage;

import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

export const StyledHeader = styled.div`
  min-width: 1920px;
  width: 100%;
  height: 100px;
  background-color: #05080e;
  border-bottom: solid 2px #6dfff3;
  display: flex;
  font-family: GyeonggiBatang;
`;
export const LogoWrapper = styled.div`
  width: 180px;
  height: 42px;
  /* margin-left: 69px; */
`;
export const Logo = styled.img`
  width: 180px;
  height: 42px;
`;

export const SocialGroup = styled.div`
  /* width: 160px; */
  display: flex;
  gap: 10px;
  justify-content: space-between;
  /* margin-left: 1472px; */
`;

export const MintLink = styled(Link)`
  background-color: #3aa55b;
  width: 240px;
  height: 50px;
  padding: 10px 20px;
  color: #fff;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  border-radius: 10px;
`;

export const SocialLogo = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;

export const ContentsWrapper = styled.div`
  /* width: 1920px; */
  min-width: 1920px;
  width: 100%;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  /* margin: 0 auto; */
  position: relative;
  padding-left: 3.6%;
  padding-right: 3.6%;
`;

export const NavWrap = styled.ul`
  width: 100%;
  margin-left: 150px;
  margin-top: 5px;
  display: flex;
  gap: 70px;
  position: relative;
  /* z-index: 1000; */

  & > li {
    padding: 20px 0;
  }

  a {
    color: white;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    font-size: 1.4rem;
    font-weight: bold;
    :hover {
      color: #1fc7d4 !important;
    }
  }

  .info {
    position: relative;
    color: white;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    font-size: 1.4rem;
    font-weight: bold;
    border: none;
    background-color: transparent;
    font-family: GyeonggiBatang;

    cursor: pointer;
    &:hover {
      color: #1fc7d4 !important;
    }

    :hover .hover-wrap {
      display: block;
    }

    .hover-wrap {
      display: none;
      flex-direction: column;
      position: absolute;
      z-index: 1000;
      width: 150px;
      background-color: white;
      border-radius: 10px;
      top: 100%;
      left: 0;
      /* margin-top: 40px; */
      padding: 15px;

      .menu {
        z-index: 1001;
        font-size: 16px;
        cursor: pointer;
        color: black;
      }
      .game-info {
        margin-bottom: 10px;
      }

      .nft-info {
        margin-bottom: 10px;
      }
    }
  }
`;

interface IIsHide {
  isHide: boolean;
}

export const MaskWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: red;
  z-index: 999;
`;

export const BodyWrapper = styled.div`
  min-width: 1920px;
  /* position: absolute;
  top: -10%; */
  width: 100%;
  height: 100%;
`;
export const BodyIntroWrapper = styled.div`
  /* min-width: 100vw; */
  width: 100%;
  margin: 0 auto;
  background-image: url('/image/bgt2.jpg');
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
  background-color: #000101;
  position: relative;
`;

export const BodyContentsWrapper = styled.div`
  /* width: 1240px; */
  min-width: 1920px;
  /* min-height: 3031px; */
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: 5%;
`;

export const IntroBgWrap = styled.div`
  width: 100%;
  padding-top: 300px;
  padding-bottom: 450px;
  margin: 0 auto;
  background-image: url('/image/intro-bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #000101;
  z-index: 100;
  @media screen and (min-width: 1921px) {
    padding-bottom: 770px;
  }
`;

export const SloganWrapper = styled.div`
  /* width: 742px;
  height: 99px; */
  /* margin-top: 403px; */
  /* margin-top: 24.7%; */
  /* margin-top: 200px; */
  text-align: center;
  white-space: pre-line;
`;

export const Slogan = styled.span`
  text-shadow: 0px 6px 27px rgba(5, 17, 31, 0.48);
  background: radial-gradient(circle at 0 0, #fff, #fbce8b),
    linear-gradient(to bottom, #fff, #fff);
  /* font-family: Gulim; */
  font-size: 48px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: -0.48px;
  text-align: center;
  color: #fff;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  /* margin-top: 130px; */
`;

export const MainLogoWrap = styled.div`
  display: flex;
  justify-content: center;
`;

export const MainLogo = styled.img`
  width: 890px;
  height: 171px;
  margin-top: 71px;
  /* margin-top: 4.9%; */
  /* padding-top: 4.9%; */
`;

export const NftWrapper = styled.div`
  /* width: 679px;
  height: 55px; */
  margin-top: 44px;
  /* margin-top: 3%; */
  text-align: center;
`;

export const Nft = styled.span`
  /* width: 679px;
  height: 55px; */

  /* font-family: Gulim; */
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.24px;
  text-align: center;
  color: #fff;
  white-space: pre-line;
  span {
    color: #ffe27e;
    font-weight: bold;
  }
`;

export const NftBold = styled.span`
  /* font-family: Gulim; */
  font-weight: bold;
`;

export const CardWrapper = styled.div`
  margin-top: 42px;
  width: 803px;
  height: 582px;
  position: relative;
`;

export const GoogleWrap = styled(Button)`
  position: absolute;
  top: -20%;
  left: 50%;
  transform: translate(-50%, -50%);

  img {
    width: 300px;
  }
`;

export const CartImage = styled.img`
  /* width: 890px; */
`;

export const VideoWrapper = styled.div`
  width: 1160px;
  height: 634px;
  margin-top: 64px;
  /* margin-top: 4.5%; */
  background-image: url('/image/green-frame.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* padding-top: 3.5%; */
  /* background-color: black; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 30px 0 30px 0;
`;

export const Divider1 = styled.img`
  width: 530px;
  height: 19px;
  margin-top: 250px;
  /* margin-top: 18.4%; */
`;

export const Divider2 = styled.img`
  width: 530px;
  height: 19px;
  /* margin-top: 18.4%; */
  top: 99.7%;
  position: absolute;
`;

export const Divider3 = styled.img`
  width: 530px;
  height: 19px;
  /* margin-top: 18.4%; */
  top: 99%;
  position: absolute;
`;

export const Barcode = styled.img`
  width: 225px;
  height: 89px;
  margin-top: 63px;
`;

export const MintingWrapper = styled.div`
  /* width: 300px; */
  height: 50px;
  /* margin-top: 16px; */
  /* margin-top: 2.6%; */
  /* font-family: Gulim; */
  padding-top: 1.1%;
  text-align: center;
`;

export const Minting = styled.span`
  /* width: 300px; */
  height: 50px;
  object-fit: contain;
  text-shadow: 0px 6px 27px rgba(5, 17, 31, 0.48);
  background: radial-gradient(circle, #fff, #fbce8b),
    linear-gradient(to bottom, #fff, #fff);
  /* font-family: Gulim; */
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.49;
  letter-spacing: -0.48px;
  text-align: center;
  color: #fff;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const MintingDetailWrapper = styled.div`
  /* width: 776px; */
  /* height: 47px; */
  margin-top: 32px;
  /* margin-top: 4%; */
  line-height: 1.27;
  text-align: center;
`;

export const MintingDetail = styled.span`
  /* width: 776px;
  height: 47px; */
  /* font-family: Gulim; */
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.22px;
  text-align: center;
  color: #fff;
`;

export const MintingBold = styled.span`
  color: #ebc38d;
  /* font-family: Gulim; */
`;

export const FigureFrame = styled.div`
  width: 1171px;
  height: 437px;
  margin-top: 23px;
  /* margin-top: 2.9%; */
  margin-bottom: 128px;
  background-image: url('/image/figureframe.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BodyGridWrapper = styled.div`
  width: 100%;
  min-width: 1920px;
  background-image: url('/image/bg2.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;
export const GridContentsWrapper = styled.div`
  /* width: 1283px; */
  min-width: 1920px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: 5%;
`;

export const GridTitleWrapper = styled.div`
  width: 600px;
  height: 49px;
  margin-top: 165px;
  text-align: center;
  /* margin-top: 5.6%; */
  /* padding-top: 3.2%; */
`;

export const GridTitle = styled.span`
  width: 600px;
  height: 49px;
  text-shadow: 0px 6px 27px rgba(5, 17, 31, 0.48);
  background-image: radial-gradient(circle at 0 0, #fff, #fbce8b),
    linear-gradient(to bottom, #fff, #fff);
  /* font-family: Gulim; */
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.48px;
  text-align: center;
  color: #fff;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const GridTitleSmall = styled.span`
  font-size: 42px;
  letter-spacing: -0.42px;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  /* font-family: Gulim; */
`;

export const Flex = styled.div`
  width: 1283px;
  display: flex;
  justify-content: space-between;
  /* margin-top: 2.1%; */
  padding-top: 4.4%;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
`;

export const Flex2 = styled.div`
  /* width: 1283px; */
  margin-top: 0.7%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 167px; */
  padding-top: 1.7%;
`;

export const BodyTeamWrapper = styled.div`
  width: 100%;
  min-width: 1920px;

  padding-bottom: 140px;
`;

export const TeamContentsWrapper = styled.div`
  /* width: 1240px; */
  min-width: 100vw;
  width: 100%;
  margin: 0 auto;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
`;

export const BodyEmailWrapper = styled.div`
  width: 100%;
  min-width: 1920px;
  background-image: url('/image/bg3.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-bottom: 140px;
`;

export const EmailContentsWrapper = styled.div`
  /* width: 1240px; */
  min-width: 100vw;
  width: 100%;
  margin: 0 auto;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
`;

export const EmailTitleWrapper = styled.div`
  /* padding-top: 4.2%; */
  margin-top: 200px;
`;

export const EmailTitle = styled.span`
  width: 323px;
  height: 36px;
  text-shadow: 0px 6px 27px rgba(5, 17, 31, 0.48);
  /* font-family: Gulim; */
  font-size: 38px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.26;
  letter-spacing: -0.38px;
  text-align: center;
  color: #fff;
`;

export const EmailTitle2 = styled.div`
  /* width: 684px; */
  height: 45px;
  margin-top: 21px;
  /* padding-top: 1.8%; */
  text-shadow: 0px 6px 27px rgba(5, 17, 31, 0.48);
  /* font-family: Gulim; */
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.48px;
  text-align: center;
  color: #fff;
  display: block;
`;

export const NameInput = styled.input`
  width: 620px;
  height: 72px;
  margin-top: 71px;
  padding: 29px 0 28px 21px;
  object-fit: contain;
  background-color: #fff;
`;

export const EmailInput = styled.input`
  width: 620px;
  height: 72px;
  margin-top: 20px;
  padding: 29px 0 28px 21px;
  object-fit: contain;
  background-color: #fff;
`;

export const TextAreaWrapper = styled.div`
  width: 620px;
  /* height: 306px; */
  padding: 35px 78px 35px 20px;
  background-color: #fff;
  white-space: pre-line;
  margin-top: 72px;
  /* padding-top: 6.1%; */
`;

export const TextArea = styled.span`
  width: 522px;
  height: 236px;
  /* font-family: Gulim; */
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.16px;
  text-align: left;
  color: #000;
`;

export const CheckBoxWrapper = styled.div`
  width: 625px;
  margin-top: 15px;
`;

export const CheckBox = styled.input`
  width: 18px;
  height: 18px;
  border-radius: 2px;
  background-color: #fff;
  position: relative;
  top: 4px;
`;

export const Agree = styled.label`
  width: 145px;
  height: 16px;
  /* font-family: Gulim; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.43;
  letter-spacing: -0.14px;
  text-align: center;
  color: #fff;
  margin-left: 12px;
`;

export const SubmitBtnWrapper = styled.div`
  width: 620px;
  height: 80px;
  margin-top: 150px;
  box-shadow: 0px 6px 10px 0 rgba(0, 0, 0, 0.25);
  background: linear-gradient(to right, #17ff8f, #4900ee);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2em;
  :hover {
    opacity: 0.9;
  }
`;

export const SubmitBtn = styled.span`
  /* width: 356px; */
  object-fit: contain;
  /* font-family: Gulim; */
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.24px;
  text-align: center;
  color: #fff;
  cursor: pointer;
`;

export const StyledFooter = styled.div`
  width: 100%;
  min-width: 1920px;
  /* height: 135px; */
  background-color: #05080e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .footer-icons {
    display: flex;
    gap: 20px;
    margin-bottom: 30px;
  }
  .btn-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* width: 1000px; */

    button {
      /* width: 250px; */
    }
    /* div {
      background-color: white;
      color: white;
    } */
  }
`;

export const Move = styled(Link)`
  padding: 10px 20px;
  color: #fff;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
`;

export const FooterImage = styled.img`
  width: 77px;
  height: 77px;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;

export const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1300,
  height: 700,
  // bgcolor: "background.paper",
  outline: 'none',
  // boxShadow: 24,
  // p: 4,
};

export const PopUpStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 750,
  bgcolor: 'background.paper',
  outline: 'none',
  boxShadow: 24,
  p: 4,
};

export const LoginStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  outline: 'none',
};

export const CloseBtn = styled.div`
  padding-left: 98%;
  cursor: pointer;
`;

export const GridCardWrapper = styled.div`
  /* width: 100%; */
  height: 392px;
  position: relative;
  opacity: 0.5;
`;
export const GridCard = styled.div`
  width: 301px;
  height: 392px;
  background-image: url('/image/border.png');
  position: relative;
`;

// export const CompleteBox = styled.div`
//   width: 300px;
//   height: 350px;
//   background-color: #201a1a;
//   position: absolute;
//   z-index: -1;
//   top: 41px;
// `;

export const CardTitleWrapper = styled.div`
  /* width: 100px; */
  height: 27px;
  position: relative;
  top: 28px;
  left: 5px;
  text-align: center;
`;
export const CardTitle = styled.div`
  width: 100%;
  height: 27px;
  position: absolute;
  object-fit: contain;
  /* font-family: Gulim; */
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: -0.26px;
  text-align: center;
  color: #201a1a;
  white-space: nowrap;
  top: 3px;
`;
export const CardDetail = styled.div`
  width: 100%;
  height: 64px;
  object-fit: contain;
  /* font-family: Gulim; */
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.28px;
  text-align: center;
  color: #fff;
  position: absolute;
  top: 185px;
  /* left: 45px; */
  white-space: pre-line;
`;

export const ModalMiddleWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WalletWrap = styled.div`
  position: absolute;
  left: 80%;
`;

export const MyPageWrap = styled.div`
  position: absolute;
  left: 70%;
`;
export const MyPageBtn = styled.button`
  min-width: 150px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0;
  padding-left: 25px;
  padding-right: 25px;
  background-color: #1a2146;
  border-radius: 24px;
  text-align: center;
  color: #e9f0fe;
  font-size: 0.875rem;
  line-height: 1.09375rem;
  font-weight: bold;
  border: none;

  :hover {
    opacity: 0.7;
  }
  cursor: pointer;
`;

export const ConnectBtn = styled.button`
  min-width: 150px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0;
  padding-left: 25px;
  padding-right: 25px;
  background-color: #1a2146;
  border-radius: 24px;
  text-align: center;
  color: #e9f0fe;
  font-size: 0.875rem;
  line-height: 1.09375rem;
  font-weight: bold;
  border: none;
  text-transform: uppercase;

  :hover {
    opacity: 0.7;
  }
  cursor: pointer;
`;

export const LoginBox = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 500px;
  padding: 20px;
  border-radius: 16px;
  box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px 0px;
  background-color: #ffffff;
  box-sizing: border-box;

  text-align: center;
  align-items: center;
`;

export const WalletSection = styled.div`
  display: flex;

  flex-direction: column;
  align-items: center;

  .close-wrap {
    position: absolute;
    left: 90%;
    cursor: pointer;
  }
  h1 {
    margin-top: 40px;
  }
  h2 {
    margin-top: 40px;
  }
`;

export const QrArea = styled.div`
  width: 220px;
  height: 220px;
`;

export const CyclubWrap = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CyclubBtn = styled.button`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  min-width: 280px;
  height: 60px;
  margin: 15px 0px 0px;
  background-color: rgb(91, 21, 213);
  border: 0px;
  border-radius: 8px;
  color: white;
  font-size: 1.125rem;
  line-height: 1.40625rem;
  outline: none;
  cursor: pointer;
  box-sizing: border-box;
`;

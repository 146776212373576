import { Box, Button, Modal, ModalProps } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';

interface IWalletCheckProps extends ModalProps {
  children: React.ReactElement;
}

const WalletCheck: React.FunctionComponent<IWalletCheckProps> = (props) => {
  return (
    <>
      <Modal {...props}>
        <Box sx={WalletCheckModal}>
          <WalletCheckCard>
            <CloseBtn
              onClick={() =>
                //@ts-ignore
                props.onClose()
              }
            >
              <img src='/image/closegray.svg' />
            </CloseBtn>
            <h2>지갑연결을 먼저 해주세요</h2>
          </WalletCheckCard>
        </Box>
      </Modal>
    </>
  );
};

export default WalletCheck;

const WalletCheckModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  outline: 'none',
};

const WalletCheckCard = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 500px;
  padding: 20px;
  border-radius: 16px;
  box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px 0px;
  background-color: #04080e;
  box-sizing: border-box;
  text-align: center;
  align-items: center;
  h2 {
    color: white;
  }
`;

const CloseBtn = styled(Button)`
  /* padding-left: 96%; */
  margin-left: 90% !important;
`;

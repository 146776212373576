import React from "react";
import styled from "styled-components";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";

const MoveTopBtn = () => {
  const onTopBtnClick = React.useCallback(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const [isScroll, setIsScroll] = React.useState(false);

  const updateScroll = () => setIsScroll(window.scrollY >= 1000);

  React.useEffect(() => {
    window.addEventListener("scroll", updateScroll);

    return () => {
      window.removeEventListener("scroll", updateScroll);
    };
  }, []);
  return (
    <>
      <TopBtn isActive={isScroll} fontSize="large" onClick={onTopBtnClick} />
    </>
  );
};

export default MoveTopBtn;

interface ITopBtn {
  isActive?: boolean;
}

const TopBtn = styled(ArrowCircleUpIcon)<ITopBtn>`
  position: fixed;
  top: 80%;
  left: 70%;
  color: #66e6b8;
  cursor: pointer;
  display: ${({ isActive }) => (isActive ? "inline-block" : "none")} !important;
`;

import { Box, Button, CircularProgress, Modal } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import walletAccountAtom from '../../atoms/walletAccount';
import web3 from '../../connection/web3';
import { contract, itemContract } from '../../constants/contract';
import {
  deleteBurnCheck,
  getUserId,
  postCheckNft,
  putBurnItem,
} from '../../utils/api';
import CommonModal from '../Modal/CommonModal';
import ExportModal from '../Modal/ExportNotice';
import ExportSuccess from '../Modal/ExportSuccess';
import IdFailed from '../Modal/IdFailed';
import ProgressModal from '../Modal/ProgressModal';

interface IMyNftProps {}

const tabs = ['FIGURE', 'STORY', 'CHARACTER'];

const MyNftWrap: React.FunctionComponent<IMyNftProps> = () => {
  const { t } = useTranslation();

  const [currentTab, setCurrentTab] = React.useState<string>('FIGURE');

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [isFailOpen, setIsFailOpen] = React.useState<boolean>(false);
  const [isNoticeOpen, setIsNoticeOpen] = React.useState<boolean>(false);
  const [tokenIds, setTokenIds] = React.useState<number[]>([]);
  const [FTokenIds, setFTokenIds] = React.useState<number[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [figureData, setFigureData] = React.useState<any[]>([]);
  const [storyData, setStoryData] = React.useState<any[]>([]);
  const [characterData, setCharacterData] = React.useState<any[]>([]);
  const [isError, setIsError] = React.useState<string>('');
  const [isCommonOpen, setIsCommonOpen] = React.useState<boolean>(false);
  const [currentProgress, setCurrentProgress] = React.useState<string>('');
  const [isProgressOpen, setIsProgressOpen] = React.useState<boolean>(false);
  const [isSuccessOpen, setIsSuccessOpen] = React.useState<boolean>(false);
  // const [isOpenModal, setIsOpenModal] = React.useState<{
  //   fail: boolean;
  //   fail: boolean;
  //   fail: boolean;
  // }>({fail:true,});

  const handleFailOpen = React.useCallback(() => {
    setIsFailOpen(true);
  }, []);
  const handleFailClose = React.useCallback(() => {
    setIsFailOpen(false);
  }, []);

  const handleSuccessOpen = React.useCallback(() => {
    setIsSuccessOpen(true);
  }, []);

  const progressOpen = React.useCallback(() => {
    setIsProgressOpen(true);
  }, []);

  const progressClose = React.useCallback(() => {
    setIsProgressOpen(false);
  }, []);

  const noticeClose = React.useCallback(() => setIsNoticeOpen(false), []);

  const handleOpen = (i: number) => () => {
    setIsOpen(true);
    setIdx(i);
  };
  const handleClose = React.useCallback(() => setIsOpen(false), []);
  const [idx, setIdx] = React.useState<number>(0);

  const [walletAccount, setWalletAccount] = useRecoilState(walletAccountAtom);

  // const aaa = async () => {
  //   const getFTokenList = await itemContract.methods
  //     .getFTokenIds(walletAccount.account)
  //     .call();

  //   setFTokenIds(getFTokenList);
  //   const MyFTokenUri = await ReqFTokenUri(FTokenIds);
  //   const MyFTokenDatas = await ReqFTokenData(MyFTokenUri);
  //   setFigureData(MyFTokenDatas.slice(0, 10));
  // };

  const getNftList = React.useCallback(async () => {
    // const 0x6B6Bbeb63256D1B9cC163dA0188078f9d67671c9 = await aaa();
    // console.log(4444);
    try {
      const getFTokenList = await itemContract.methods
        .getFTokenIds(walletAccount.account)
        .call();

      setFTokenIds(getFTokenList);

      const MyFTokenUri = await ReqFTokenUri(getFTokenList);

      const MyFTokenDatas = await ReqFTokenData(MyFTokenUri);

      setFigureData(MyFTokenDatas);
    } catch (e: any) {
      console.log(e, '1');
    }

    try {
      const getStoryList = await itemContract.methods
        .getTokenIds(walletAccount.account, 0)
        .call();
      setTokenIds(getStoryList);

      // console.log(getStoryList, 'getStoryList');

      const MyTokenUri = await ReqTokenUri(getStoryList);

      // console.log(MyTokenUri, 'MyTokenUri');

      const MyTokenDatas = await ReqTokenData(MyTokenUri);

      // console.log(MyTokenDatas, 'MyTokenDatas');
      setStoryData(MyTokenDatas);
    } catch (e: any) {
      console.log(e, '2');
    }
    try {
      const getCharacerList = await itemContract.methods
        .getTokenIds(walletAccount.account, 1)
        .call();
      setTokenIds(getCharacerList);

      console.log(getCharacerList, 'getCharacerList');

      const MyTokenUri = await ReqTokenUri(getCharacerList);

      console.log(MyTokenUri, 'MyTokenUri');

      const MyTokenDatas = await ReqTokenData(MyTokenUri);

      console.log(MyTokenDatas, 'MyTokenDatas');

      setCharacterData(MyTokenDatas);
    } catch (error) {}
  }, []);

  const handleClickTab = (item: string) => () => {
    setCurrentTab(item);
  };

  const exportItem = React.useCallback(
    (item: any) => async () => {
      setIsLoading(true);
      setIsProgressOpen(true);
      setCurrentProgress('게임 내보내기 중입니다.');

      let miId = null;

      try {
        const gasPrice = await web3.eth.getGasPrice();
        const getId = await getUserId(walletAccount.account);
        if (getId.message === 'Fail') {
          setIsFailOpen(true);
        } else {
          try {
            if (currentTab === 'FIGURE') {
              const checkNft = await postCheckNft({
                tokenId: parseInt(item.id),
                nftType: currentTab,
                address: walletAccount.account,
                img: item.data.image,
              });

              miId = checkNft.result;

              const isApproval = await contract.methods
                .isApprovedForAll(
                  walletAccount.account,
                  process.env.REACT_APP_ITEM_CONTRACT_ADDRESS as string
                )
                .call();
              if (!isApproval) {
                await contract.methods
                  .setApprovalForAll(
                    process.env.REACT_APP_ITEM_CONTRACT_ADDRESS as string,
                    true
                  )
                  .send({
                    from: walletAccount.account,
                    gas: 3000000,
                    gasPrice,
                  });
              }

              await itemContract.methods.lockup(item.id).send({
                from: walletAccount.account,
                gas: 3000000,
                gasPrice,
              });

              await putBurnItem({
                // tokenId: Number(item.id),
                // nftType: currentTab,
                miId,
                address: walletAccount.account,
                // img: item.data.image,
              });
              const deleteFigureData = figureData.filter(
                (data) => data.id !== item.id
              );
              setFigureData(deleteFigureData);
              setIsSuccessOpen(true);
            }
            if (currentTab !== 'FIGURE') {
              const checkNft = await postCheckNft({
                tokenId: parseInt(item.id),
                nftType: currentTab,
                address: walletAccount.account,
                itemUid: item.data.uId,
              });

              miId = checkNft.result;

              await itemContract.methods.removeNFT(item.id).send({
                from: walletAccount.account,
                gas: 3000000,
                gasPrice,
              });

              // console.log('aaa');
              await putBurnItem({
                miId,
                address: walletAccount.account,
              });

              const deleteStoryData = storyData.filter(
                (data) => data.id !== item.id
              );
              setStoryData(deleteStoryData);

              // console.log('bbb');

              // const deleteData = storyData.filter((data) => data.id !== item.id);

              // setStoryData(deleteData);
              setIsSuccessOpen(true);
            }
          } catch (e: any) {
            console.log(e.code);

            if (e.code === 4001) {
              await deleteBurnCheck({ miId });
            }

            if (e.status === 409) {
              if (currentTab === 'STORY') {
                const deleteStoryData = storyData.filter(
                  (data) => data.id !== item.id
                );
                setStoryData(deleteStoryData);
              }
              if (currentTab === 'FIGURE') {
                const deleteFigureData = figureData.filter(
                  (data) => data.id !== item.id
                );
                setFigureData(deleteFigureData);
              }
            }
            // TODO: handleError(message: string) 함수 생성 후 해당 함수에 메시지 보내서 보여주게 수정
            if (e.status === 400) {
              setIsError('UserId And Address MissMatching');
              setIsCommonOpen(true);
            }
            if (e.status === 401) {
              setIsError('유효한 게임 ID 값이 아닙니다.');
              setIsCommonOpen(true);
            }
            if (e.status === 402) {
              setIsError('유효한 아이템 ID 값이 아닙니다.');
              setIsCommonOpen(true);
            }
            if (e.status === 500) {
              setIsError('DB Error');
              setIsCommonOpen(true);
            }
            if (e.status === 1000) {
              setIsError('exception message');
              setIsCommonOpen(true);
            }
          }
        }
      } catch (e: any) {
        console.log(e);
      } finally {
        setIsProgressOpen(false);
        setIsLoading(false);
        setIsOpen(false);
        setTimeout(() => {
          getNftList();
          // console.log('delay');
        }, 500);
      }
    },
    [idx, setIdx, currentTab]
  );

  const ReqFTokenData: any = async (FTokenDataArray: number[]) => {
    let FTokenDatas: any = [];

    const getFTokenData = await Promise.allSettled(
      FTokenDataArray.map(async (item: any) => ({
        id: item.id,
        data: await fetch(item.data).then((r) => r.json()),
      }))
    );

    const fulfilledFTokenDatas = getFTokenData.filter(
      (item) => item.status === 'fulfilled'
    );

    const rejectedFTokenDatas = getFTokenData.filter(
      (item) => item.status === 'rejected'
    );

    FTokenDatas = [...FTokenDatas, ...fulfilledFTokenDatas];

    if (rejectedFTokenDatas.length < 0) {
      await new Promise((r) => setTimeout(r, 1000));

      const fulfilledFTokenDatasIds = fulfilledFTokenDatas.map(
        //@ts-ignore
        (item) => item.value.id
      );

      const rejectedFTokenDatasIds = FTokenDataArray.filter(
        //@ts-ignore
        (item) => fulfilledFTokenDatasIds.indexOf(item) === -1
      );

      const res: any = await ReqFTokenData(rejectedFTokenDatasIds);

      //@ts-ignore
      FTokenDatas = [...FTokenDatas, ...res];
    }

    FTokenDatas = FTokenDatas.map((item: any) => item.value);

    return FTokenDatas;
  };

  const ReqTokenData = async (TokenDataArray: any) => {
    let TokenDatas: any = [];

    const getTokenData = await Promise.allSettled(
      TokenDataArray.map(async (item: any) => ({
        id: item.id,
        data: await fetch(`${item.data}`).then((r) => r.json()),
      }))
    );

    const fulfilledTokenDatas = getTokenData.filter(
      (item) => item.status === 'fulfilled'
    );

    const rejectedTokenDatas = getTokenData.filter(
      (item) => item.status === 'rejected'
    );

    TokenDatas = [...TokenDatas, ...fulfilledTokenDatas];

    if (rejectedTokenDatas.length < 0) {
      await new Promise((r) => setTimeout(r, 1000));

      const fulfilledTokenDatasIds = fulfilledTokenDatas.map(
        //@ts-ignore
        (item) => item.value.id
      );

      const rejectedTokenDatasIds = TokenDataArray.filter(
        //@ts-ignore
        (item) => fulfilledTokenDatasIds.indexOf(item) === -1
      );

      const res = await ReqTokenData(rejectedTokenDatasIds);

      //@ts-ignore
      TokenDatas = [...TokenDatas, ...res];
    }

    TokenDatas = TokenDatas.map((item: any) => item.value);
    return TokenDatas;
  };

  const ReqTokenUri = async (tokenIdArray: number[]) => {
    let TokenUriArray: any = [];

    const getTokenUri = await Promise.allSettled(
      tokenIdArray.map(async (item) => ({
        id: item,
        data: await itemContract.methods.tokenURI(item).call(),
      }))
    );

    const TokenFulfilledItems = getTokenUri.filter(
      (item) => item.status === 'fulfilled'
    );

    const TokenRejedtedItems = getTokenUri.filter(
      (item) => item.status === 'rejected'
    );

    TokenUriArray = [...TokenUriArray, ...TokenFulfilledItems];

    if (TokenRejedtedItems.length < 0) {
      const FulfilledItemsTokenIds = TokenFulfilledItems.map(
        //@ts-ignore
        (item) => item.value.id
      );

      const RejectedItemsTokenIds = tokenIdArray.filter(
        (item) => FulfilledItemsTokenIds.indexOf(item) === -1
      );

      const res = await ReqTokenUri(RejectedItemsTokenIds);

      //@ts-ignore
      TokenUriArray = [...TokenUriArray, ...res];
    }
    TokenUriArray = TokenUriArray.map((item: any) => item.value);

    return TokenUriArray;
  };

  const ReqFTokenUri = async (ftokenIdArray: number[]) => {
    // 값을 넣을 배열 생성
    // 풀필, 리젝티드 나눠서 넣음

    let FTokenUriArray: any = [];

    // tokenId를 넣어 url을 요청

    const getFTokenUri = await Promise.allSettled(
      ftokenIdArray.map(async (item) => ({
        id: item,
        data: await contract.methods.tokenURI(item).call(),
      }))
    );

    // console.log('getFTokenUri');
    // console.log(getFTokenUri);
    // console.log('getFTokenUri');

    // 풀필된 요청(성공요청) 필터처리

    const FTokenFulfilledItems = getFTokenUri.filter(
      (item) => item.status === 'fulfilled'
    );

    // 리젝된 요청(실패요청) 필터처리

    const FTokenRejectedItems = getFTokenUri.filter(
      (item) => item.status === 'rejected'
    );

    // console.log('FTokenRejectedItems');
    // console.log(FTokenRejectedItems.length);
    // console.log(FTokenRejectedItems);

    //미리 만들어 놓은 배열에 넣는다.

    FTokenUriArray = [...FTokenUriArray, ...FTokenFulfilledItems];

    // 리젝 요청을 시작하는 로직
    // FTokenRejectedItems.length가 있다면 리젝된 아이템이 있는것

    if (FTokenRejectedItems.length > 0) {
      // 요청 주기 설정 ?....
      await new Promise((r) => setTimeout(r, 1000));

      //성공된 아이템의 tokenId를 기록
      const FulfilledItemsFTokenIds = FTokenFulfilledItems.map(
        // @ts-ignore
        (item) => item.value.id
      );
      // console.log('FulfilledItemsFTokenIds');
      // console.log(FulfilledItemsFTokenIds);

      const RejectedFTokenItemsIds = ftokenIdArray.filter(
        //성공한 아이템의 인덱스를 1씩 줄인다.?.
        //이러면 실패한 아이템의 인덱스만 남을것
        (item) => FulfilledItemsFTokenIds.indexOf(item) === -1
      );
      // console.log('RejectedFTokenItemsIds');
      // console.log(RejectedFTokenItemsIds);

      //실패한 아이템의 인덱스를 담은 배열을 다시 a에 넣는다.
      const res = await ReqFTokenUri(RejectedFTokenItemsIds);
      // console.log('res');
      // console.log(res);

      //그리고 다시 FTokenUriArray 배열에 넣는다.
      FTokenUriArray = [...FTokenUriArray, ...res];
    }

    // console.log('33333', FTokenUriArray);

    //FTokenUriArray값중 필요한 값만 넣기 위해 map을 돌린다.
    FTokenUriArray = FTokenUriArray.map((item: any) => item.value);

    // console.log('4444', FTokenUriArray);
    //FTokenUriArray 값을 리턴한다.
    return FTokenUriArray;
  };

  React.useEffect(() => {
    getNftList();
  }, [currentTab]);

  // console.log('mynft', currentTab);

  // console.log('abc', figureData.slice(0, 10));

  return (
    <>
      <Wrap>
        <TitleWrap>
          <MyNftTitle>{t('mynft.title')}</MyNftTitle>
        </TitleWrap>
        <TabWrap>
          {tabs.map((item, i) => (
            <TabBtn key={i} onClick={handleClickTab(item)}>
              <TabTitle currentTab={currentTab} item={item}>
                {item}
              </TabTitle>
            </TabBtn>
          ))}
        </TabWrap>

        {currentTab === 'FIGURE' ? (
          figureData?.length === 0 ? (
            <CardWrap>
              <CardGridWrap>
                <div style={{ width: '100%', height: '100%' }}>
                  <h1 style={{ color: 'white', textAlign: 'center' }}>
                    {t('mynft.desc')}
                  </h1>
                </div>
              </CardGridWrap>
            </CardWrap>
          ) : (
            <CardWrap>
              <CardGridWrap>
                {figureData?.map((item: any, i) => (
                  <Card key={i} onClick={handleOpen(i)}>
                    <ReactPlayer
                      url={item?.data?.image}
                      width='100%'
                      height='100%'
                    />
                  </Card>
                ))}
              </CardGridWrap>
            </CardWrap>
          )
        ) : currentTab === 'STORY' ? (
          storyData?.length === 0 ? (
            <CardWrap>
              <CardGridWrap>
                <div style={{ width: '100%', height: '100%' }}>
                  <h1 style={{ color: 'white', textAlign: 'center' }}>
                    {t('mynft.desc')}
                  </h1>
                </div>
              </CardGridWrap>
            </CardWrap>
          ) : (
            <CardWrap>
              <CardGridWrap>
                {storyData.map((item: any, i) => (
                  <Card key={i} onClick={handleOpen(i)}>
                    <ReactPlayer
                      url={item?.data?.image}
                      width='100%'
                      height='100%'
                    />
                  </Card>
                ))}
              </CardGridWrap>
            </CardWrap>
          )
        ) : currentTab === 'CHARACTER' && characterData?.length === 0 ? (
          <CardWrap>
            <CardGridWrap>
              <div style={{ width: '100%', height: '100%' }}>
                <h1 style={{ color: 'white', textAlign: 'center' }}>
                  {t('mynft.desc')}
                </h1>
              </div>
            </CardGridWrap>
          </CardWrap>
        ) : (
          <CardWrap>
            <CardGridWrap>
              {characterData.map((item: any, i) => (
                <Card key={i} onClick={handleOpen(i)}>
                  <img src={item?.data?.image} width='100%' height='100%' />
                </Card>
              ))}
            </CardGridWrap>
          </CardWrap>
        )}
      </Wrap>
      <ReactPlayer
        url={
          'https://metasoul.s3.ap-northeast-2.amazonaws.com/character/NFT_Character(%E1%84%8F%E1%85%A2%E1%84%85%E1%85%B5%E1%86%A8%E1%84%90%E1%85%A5)/character_109.jpg'
        }
        width={500}
        height={500}
      />
      <Modal open={isOpen} onClose={handleClose}>
        <Box sx={CardModal}>
          <ModalCard>
            <CardContentsWrap>
              <ContentsLeft>
                {currentTab !== 'CHARACTER' ? (
                  <ReactPlayer
                    url={
                      currentTab === 'FIGURE'
                        ? figureData[idx]?.data?.image
                        : storyData[idx]?.data?.image
                    }
                    width='100%'
                    height='100%'
                    playing
                    muted
                    loop
                  />
                ) : (
                  <img
                    src={characterData[idx]?.data?.image}
                    width='100%'
                    height='100%'
                  />
                )}
              </ContentsLeft>
              <ContentsRight>
                <CloseBtn onClick={handleClose}>
                  <img src='/image/closegray.svg' />
                </CloseBtn>

                {currentTab === 'FIGURE' ? (
                  <DescWrap>
                    <div>
                      <CardTitle>{t('mynft.card.name')}</CardTitle>
                      <Desc>{figureData[idx]?.data?.name}</Desc>
                    </div>
                    <div>
                      <CardTitle>{t('mynft.card.desc')}</CardTitle>
                      <Desc>{figureData[idx]?.data?.description}</Desc>
                    </div>
                    <div>
                      <CardTitle>{t('mynft.card.edition')}</CardTitle>
                      <Desc>{figureData[idx]?.data?.edition}</Desc>
                    </div>
                    {figureData[idx]?.data?.attributes?.map(
                      (item: any, i: number) => (
                        <div key={i}>
                          <CardTitle>{item?.trait_type}</CardTitle>
                          <Desc>{item?.value}</Desc>
                        </div>
                      )
                    )}
                  </DescWrap>
                ) : (
                  <DescWrap>
                    <div>
                      <CardTitle>{t('mynft.card.name')}</CardTitle>
                      <Desc>
                        {currentTab === 'STORY'
                          ? storyData[idx]?.data?.name
                          : characterData[idx]?.data?.name}
                      </Desc>
                    </div>
                    <div>
                      <CardTitle>{t('mynft.card.desc')}</CardTitle>
                      <Desc>
                        {' '}
                        {currentTab === 'STORY'
                          ? storyData[idx]?.data?.description
                          : characterData[idx]?.data?.description}
                      </Desc>
                    </div>
                    <div>
                      <CardTitle>{t('mynft.card.grade')}</CardTitle>
                      <Desc>
                        {' '}
                        {currentTab === 'STORY'
                          ? storyData[idx]?.data?.grade
                          : characterData[idx]?.data?.grade}
                      </Desc>
                    </div>
                  </DescWrap>
                )}
                <BtnWrap>
                  <ExportButton>
                    <Button
                      onClick={
                        currentTab === 'FIGURE'
                          ? exportItem(figureData[idx])
                          : currentTab === 'STORY'
                          ? exportItem(storyData[idx])
                          : exportItem(characterData[idx])
                      }
                      // onClick={() => setIsNoticeOpen(true)}
                    >
                      {t('mynft.export')}
                    </Button>
                  </ExportButton>
                </BtnWrap>
              </ContentsRight>
            </CardContentsWrap>
          </ModalCard>
        </Box>
      </Modal>

      {/* @ts-ignore */}
      <IdFailed open={isFailOpen} onClose={() => setIsFailOpen(false)} />
      {/* @ts-ignore */}
      <ExportModal open={isNoticeOpen} onClose={noticeClose} />
      {/* @ts-ignore */}
      <CommonModal
        open={isCommonOpen}
        onClose={() => setIsCommonOpen(false)}
        isError={isError}
      />
      {/* @ts-ignore */}
      <ProgressModal
        open={isProgressOpen}
        currentProgress={currentProgress}
        isLoading={isLoading}
      />
      {/* @ts-ignore */}
      <ExportSuccess
        open={isSuccessOpen}
        onClose={() => setIsSuccessOpen(false)}
      />
    </>
  );
};

export default React.memo(MyNftWrap);

const Wrap = styled.div`
  width: 1280px;
  background-color: #04080e;
  margin: 0 auto;
  border-radius: 15px;
`;

const CardWrap = styled.div`
  width: 1280px;
  background-color: #04080e;
  margin: 0 auto;
  border-radius: 15px;
`;

const CardGridWrap = styled.div`
  width: 1280px;
  background-color: #04080e;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  flex-wrap: wrap;
  border-radius: 15px;
  padding: 15px;
`;

const Card = styled.div`
  width: 400px;
  margin-top: 20px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const TabWrap = styled.div`
  width: 1280px;
  background-color: #04080e;
  margin: 0 auto;
  border-radius: 15px;
  /* margin-top: 60px; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
`;

interface ITabBtn {
  currentTab?: string;
  item?: string;
}

const TabBtn = styled(Button)<ITabBtn>`
  cursor: pointer;
`;

const TabTitle = styled.h1<ITabBtn>`
  &:hover {
    color: #1fc7d4 !important;
  }
  font-family: GyeonggiBatang;
  color: ${({ currentTab, item }) =>
    currentTab === item ? '#1fc7d4' : 'white'};
`;

const CardModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  // height: 600,
  outline: 'none',
};

const ModalCard = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 900px;
  min-width: 900px;
  padding: 20px;
  border-radius: 16px;
  box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px 0px;
  background-color: #04080e;
  box-sizing: border-box;
`;

const CardContentsWrap = styled.div`
  display: flex;
  padding: 20px;
  gap: 20px;
`;

const ContentsLeft = styled.div`
  img {
    width: 100%;
    height: 100%;
    border-radius: 16px;
  }
  width: 50%;
`;
const ContentsRight = styled.div`
  width: 50%;
`;

const DescWrap = styled.div`
  display: flex;
  flex-direction: column;
  div {
    display: flex;
    margin-left: 40px;
  }
`;

const CardTitle = styled.p`
  width: 30%;
  color: white;
`;

const Desc = styled.p`
  width: 70%;
  font-size: 14px;
  line-height: 1.5;
  color: white;
  text-align: left;
  white-space: pre-line;
`;

const CloseBtn = styled(Button)`
  /* padding-left: 96%; */
  margin-left: 90% !important;
`;

const ExportButton = styled.div`
  width: 90%;
  background-color: #565fd6;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    width: 100%;
    color: white;
  }
`;

const BtnWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const TitleWrap = styled.div`
  width: 1280px;
  height: 100px;
  background-color: #04080e;
  margin: 0 auto;
  border-radius: 15px;
  margin-top: 60px;
  display: flex;

  justify-content: center;
  align-items: center;
`;

const MyNftTitle = styled.h1`
  text-align: center;
`;

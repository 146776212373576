import styled from 'styled-components';

export const MintSelectWrap = styled.div`
  min-width: 1920px;
  margin: 0 auto;
  padding: 100px 0;
  background-color: black;
  color: white;
`;

export const NftWrap = styled.div`
  width: 1280px;
  background-color: red;
  margin: 0 auto;
  padding: 50px 110px 50px 110px;
  margin-top: 150px;
  position: relative;

  .title {
    font-size: 50px;
    color: #00dde9;
    text-transform: capitalize;
    span {
      font-size: 40px;
      color: white;
    }
  }
  h1 {
    font-size: 30px;
    color: white;
  }
  h2 {
    font-size: 20px;
    color: white;
    margin-top: 40px;
  }
  background-color: #1a2147;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
`;

export const LeftWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const RightWrap = styled.div`
  width: 40%;
`;

export const PriceWrap = styled.div`
  font-size: 50px;
  margin-top: 40px;
  h1 {
    font-size: 30px;
  }
`;

export const DetailBtnWrap = styled.div`
  width: 180px;
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    width: 180px;
    height: 60px;
    background-color: #521bb0;
    font-size: 1.125rem;
    margin-top: 40px;
    cursor: pointer;
    :hover {
      opacity: 0.7;
    }
  }
`;

export const DetailBtn = styled.div``;

export const OpacityWrap = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 100px;
  font-weight: bold;
`;

export const CountdownWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20px 0;
  margin-top: 20px;
  margin-bottom: 20px;
  color: black;
  .item-wrap {
    display: flex;
    flex-direction: column;
    width: 100px;
    .item {
      background-color: white;
      height: 100px;
      line-height: 100px;
      margin-bottom: 5px;
      border-radius: 10px;
      text-align: center;
      font-size: 60px;
    }
    .title {
      color: white;
      font-weight: bold;
      text-align: center;
    }
  }
`;

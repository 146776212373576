import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../../components/Footer';
import Header from '../../components/Header';

import {
  Arrow,
  BarWrap,
  Bgwrap,
  Desc1,
  Desc2,
  Desc3,
  Desc4,
  Desc5,
  Desc6,
  Desc7,
  DescWrap,
  ImgWrap1,
  ImgWrap2,
  ImgWrap3,
  ImgWrap4,
  ImgWrap5,
  ImgWrap6,
  ImgWrap7,
  LogoWrap,
  MainTitle,
  Pointer1,
  Pointer2,
  Pointer3,
  Pointer4,
  SubDescWrap,
  SubTitle,
  Text,
  TextWrap,
  TextWrap2,
} from './styles';

interface IExportInfoProps {}

const ExportInfo: React.FunctionComponent<IExportInfoProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <Bgwrap>
        <LogoWrap>
          <img src='/image/intro-logo.png' alt='logo' />
        </LogoWrap>
        <MainTitle>{t('exportinfo.title')}</MainTitle>
        <SubTitle>{t('exportinfo.slogan')}</SubTitle>
        <DescWrap>
          <h1>{t('exportinfo.desc1')}</h1>
          <ImgWrap1>
            <img src='/image/metamask.png' alt='metamask' />
          </ImgWrap1>
          <h1>{t('exportinfo.desc2')}</h1>
          <ImgWrap2>
            <SubDescWrap>
              <img src='/image/game1.png' alt='game1' />
              <h2
                dangerouslySetInnerHTML={{ __html: t('exportinfo.subdesc2-1') }}
              />
              <Pointer1>
                <img src='/image/pointer1.png' alt='pointer' />
              </Pointer1>
            </SubDescWrap>

            <SubDescWrap>
              <img src='/image/game2.png' alt='game2' />
              <h2
                dangerouslySetInnerHTML={{ __html: t('exportinfo.subdesc2-2') }}
              />
              <Pointer2>
                <img src='/image/Pointer2.png' alt='pointer' />
              </Pointer2>
            </SubDescWrap>
          </ImgWrap2>
          <h1>{t('exportinfo.desc3')}</h1>
          <ImgWrap3>
            <img src='/image/game3.png' alt='game3' />
            <Pointer3>
              <img src='/image/pointer3.png' alt='pointer3' />
            </Pointer3>
          </ImgWrap3>
          <h1>{t('exportinfo.desc4')}</h1>
          <ImgWrap4>
            <img src='/image/game4.png' alt='game4' />
            <Pointer4>
              <img src='/image/pointer3.png' alt='pointer4' />
            </Pointer4>
          </ImgWrap4>
          <ImgWrap5>
            <SubDescWrap>
              <img src='/image/game5.png' alt='game5' />
              <h2
                dangerouslySetInnerHTML={{ __html: t('exportinfo.subdesc4-1') }}
              />
            </SubDescWrap>
            <SubDescWrap>
              <img
                src='/image/game6.png'
                alt='game6'
                style={{ paddingTop: 13 }}
              />
              <h2
                style={{ marginTop: 100 }}
                dangerouslySetInnerHTML={{ __html: t('exportinfo.subdesc4-2') }}
              />
            </SubDescWrap>
          </ImgWrap5>
          <h1>{t('exportinfo.desc5')}</h1>
          <h1 className='game5-text'>{t('exportinfo.desc5-1')}</h1>
          <ImgWrap6>
            <img src='/image/game7.png' alt='game' className='inventory' />
            <BarWrap>
              <img src='/image/bar.png' alt='bar' />
            </BarWrap>
            <Desc1
              dangerouslySetInnerHTML={{ __html: t('exportinfo.subdesc5-1') }}
            />
            <Desc2>{t('exportinfo.subdesc5-2')}</Desc2>
            <Desc3
              dangerouslySetInnerHTML={{ __html: t('exportinfo.subdesc5-3') }}
            />
            <Desc4
              dangerouslySetInnerHTML={{ __html: t('exportinfo.subdesc5-4') }}
            />
            <Desc5
              dangerouslySetInnerHTML={{ __html: t('exportinfo.subdesc5-5') }}
            />
            <Desc6
              dangerouslySetInnerHTML={{ __html: t('exportinfo.subdesc5-6') }}
            />
            <Desc7
              dangerouslySetInnerHTML={{ __html: t('exportinfo.subdesc5-7') }}
            />
          </ImgWrap6>
          <TextWrap>
            <Text>
              <Arrow fontSize='large' />
              <h2
                dangerouslySetInnerHTML={{ __html: t('exportinfo.subdesc5-8') }}
              />
            </Text>
            <Text>
              <Arrow fontSize='large' />
              <h2
                dangerouslySetInnerHTML={{
                  __html: t('exportinfo.subdesc5-9'),
                }}
              />
            </Text>
            <Text>
              <Arrow fontSize='large' />
              <h2
                dangerouslySetInnerHTML={{
                  __html: t('exportinfo.subdesc5-10'),
                }}
              />
            </Text>
            <Text style={{ paddingLeft: 35 }}>
              <h2
                dangerouslySetInnerHTML={{
                  __html: t('exportinfo.subdesc5-11'),
                }}
              />
            </Text>
            <Text>
              <Arrow fontSize='large' />
              <h2
                dangerouslySetInnerHTML={{
                  __html: t('exportinfo.subdesc5-12'),
                }}
              />
            </Text>
          </TextWrap>
          <h1
            dangerouslySetInnerHTML={{
              __html: t('exportinfo.desc6'),
            }}
          />
          <ImgWrap7>
            <SubDescWrap>
              <img src='/image/game8.png' alt='game8' />
              <h2>{t('exportinfo.subdesc6-1')}</h2>
            </SubDescWrap>
            <SubDescWrap>
              <img src='/image/game9.png' alt='game9' />
              <h2>{t('exportinfo.subdesc6-2')}</h2>
            </SubDescWrap>
            <SubDescWrap>
              <img src='/image/game10.png' alt='game10' />
              <h2>{t('exportinfo.subdesc6-3')}</h2>
            </SubDescWrap>
          </ImgWrap7>
          <TextWrap2>
            <Text>
              <Arrow fontSize='large' />
              {t('exportinfo.subdesc6-4')}
            </Text>
            <Text>
              <Arrow fontSize='large' />
              {t('exportinfo.subdesc6-5')}
            </Text>
            <Text>
              <Arrow fontSize='large' />
              {t('exportinfo.subdesc6-6')}
            </Text>
          </TextWrap2>
        </DescWrap>
      </Bgwrap>
      <Footer />
    </>
  );
};

export default ExportInfo;

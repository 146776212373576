import { CircularProgress } from '@mui/material';
import BigNumber from 'bignumber.js';
import { create } from 'ipfs-http-client';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import { useInterval } from 'usehooks-ts';
import { resourceLimits } from 'worker_threads';
import priceAtom from '../../atoms/price';
import walletAccountAtom from '../../atoms/walletAccount';
import web3 from '../../connection/web3';
import { itemContract } from '../../constants/contract';
import { getTokenPrice } from '../../utils';
import {
  getPackData,
  getNftAmount,
  postPackData,
  putPackData,
} from '../../utils/api';
import MintingModal from '../Modal/MintingModal';
import MintingSuccess from '../Modal/MintingSuccess';

interface IPacksBuyProps {
  name: 'story' | 'character';
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const client = create({
  host: 'ipfs.infura.io',
  port: 5001,
  protocol: 'https',
});

const PacksBuy: React.FunctionComponent<IPacksBuyProps> = ({
  name,
  setIsLoading,
}) => {
  const { t } = useTranslation();

  // const [price, setPrice] = React.useState<number>(0);
  const [klayPrice, setKlayPrice] = useRecoilState(priceAtom);

  const [mintData, setMintData] = React.useState<string>('');
  const [grade, setGrade] = React.useState<number>(0);
  const [stocks, setStocks] = React.useState<number>(0);

  const [walletAccount, setWalletAccount] = useRecoilState(walletAccountAtom);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const handleClose = React.useCallback(() => setIsOpen(false), []);

  const [isMintingSuccess, setIsMintingSuccess] =
    React.useState<boolean>(false);

  const setToken = React.useCallback(async () => {
    const tokenPrice = await getTokenPrice();

    if (tokenPrice) {
      setKlayPrice(
        parseFloat((10000 / tokenPrice['klay-token'].krw).toFixed(2))
      );
    }
  }, []);

  const onClickAlert = () => {
    alert('점검중 입니다.');
  };

  const onClickMint = React.useCallback(async () => {
    let resultGrade = null;
    setIsLoading(true);
    setIsOpen(true);

    try {
      const gasPrice = await web3.eth.getGasPrice();
      const result = await getPackData(name);

      setGrade(result.grade);
      resultGrade = result.grade;
      // const { path } = await client.add(JSON.stringify(result));
      await itemContract.methods
        .mintPay(
          name === 'story'
            ? `https://metasoul.s3.ap-northeast-2.amazonaws.com/storyjson/${result.sId}.json`
            : `https://metasoul.s3.ap-northeast-2.amazonaws.com/characterjson/${result.description}.json`,
          name === 'story' ? 0 : 1
        )
        .send({
          from: walletAccount.account,
          value: new BigNumber(klayPrice * 10 ** 18),
          gas: 3000000,
          gasPrice,
        });
      await postPackData(name, {
        address: walletAccount.account,
        uId: result.uId,
        grade: result.grade,
      });
    } catch (e: any) {
      // resultGrade 사용

      await putPackData(name, { grade: resultGrade });
    } finally {
      setIsOpen(false);
      setIsLoading(false);
      setIsMintingSuccess(true);
    }
  }, [klayPrice, grade, setGrade]);

  // const onClickMint = React.useCallback(async () => {
  //   setIsLoading(true);
  //   setIsOpen(true);
  //   try {
  //     const result = await getPackData();

  //     console.log('33434', result);
  //     setGrade(result.grade);

  //     const { path } = await client.add(JSON.stringify(result));

  //     const mint = await itemContract.methods
  //       .mintPay(path, name === 'story' ? 0 : 1)
  //       .send({
  //         from: walletAccount.account,
  //         value: new BigNumber(price * 10 ** 18),
  //         gas: 3000000,
  //         gasPrice: 750000000000,
  //       });

  //     console.log(mint);

  //     const postData = await postPackData({
  //       address: walletAccount.account,
  //       uId: result.uId,
  //       grade: result.grade,
  //     });
  //     console.log('postData', postData);
  //   } catch (e: any) {
  //     cancelData();
  //   } finally {
  //     setIsOpen(false);
  //     setIsLoading(false);
  //   }
  // }, [price, grade, setGrade]);

  // const cancelData = React.useCallback(async () => {
  //   const cancel = await putPackData({ grade: grade });
  //   console.log(1123);
  //   console.log(cancel);
  // }, [grade, setGrade]);

  const getStock = React.useCallback(async () => {
    const result = await getNftAmount(name);
    setStocks(result.count);
  }, []);

  React.useEffect(() => {
    setToken();
    getStock();
  }, []);

  useInterval(() => {
    setToken();
  }, 1000 * 60 * 10);

  return (
    <>
      <BuyWrap>
        <h2 className='title'>{name.toUpperCase()} NFT</h2>
        <p className='stock'>
          {stocks} {t('packinfos.story.buy.stocks')}
        </p>

        <InfoMenuWrap>
          <InfoMenuButton type='button'>
            <img src={'/image/KLAY_NFT.svg'} alt='klay' />
            KLAY
          </InfoMenuButton>
        </InfoMenuWrap>
        <TotalPriceWrap>
          <p>{t(`packinfos.${name}.buy.price`)}</p>
          <p className='desc'>{klayPrice} KLAY</p>
        </TotalPriceWrap>
        <BuyButton type='button' onClick={onClickMint}>
          {t(`packinfos.${name}.buy.buybtn`)}
        </BuyButton>
        {/* @ts-ignore */}
        <MintingModal open={isOpen} onClose={handleClose} />
      </BuyWrap>
      {/* @ts-ignore */}
      <MintingSuccess
        open={isMintingSuccess}
        onClose={() => setIsMintingSuccess(false)}
      />
    </>
  );
};

// interface IStock {
//   stock: number;
// }

export default PacksBuy;

const BuyWrap = styled.div`
  padding-left: 100px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  & > .title {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  & > .stock {
    margin-bottom: 40px;
    font-size: 30px;
  }
`;

const InfoMenuWrap = styled.div`
  display: flex;

  width: 130px;
  border-radius: 50px;
  background-color: #1a2147;
  margin-bottom: 20px;
`;

interface IInfoMenuButton {
  isActive?: boolean;
}

const InfoMenuButton = styled.button<IInfoMenuButton>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 20px 0;
  border: 0;
  border-radius: 50px;
  background-color: ${(props) => (props.isActive ? '#1A2147' : 'transparent')};
  color: white;
  cursor: pointer;
  & > img {
    margin-right: 10px;
  }
`;

const TotalPriceWrap = styled.div`
  .title {
    margin-bottom: 5px;
    font-size: 20px;
  }
  .desc {
    font-size: 2rem;
    color: #04dde9;
    font-weight: bold;
    margin-top: 20px;
  }
  p {
    margin-top: 20px;
  }

  /* @media (max-width: 768px) {
    p {
      text-align: center;
    }
  } */
`;

const BuyButton = styled.button`
  margin-top: 20px;
  width: 95%;
  border: 0;
  border-radius: 10px;
  background-color: #1a2147;
  font-size: 1rem;
  font-weight: bold;
  color: white;
  padding: 20px 80px;
  font-size: 30px;
  cursor: pointer;
`;

const ExportDesc = styled.div`
  h2 {
    margin-top: 40px;
  }
`;

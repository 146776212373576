import {
  IBoard,
  IBurnItem,
  ICancelPack,
  ICoinPayment,
  ICYPayment,
  IDeleteBurnCheck,
  IExportItem,
  IItemDetail,
  IItemLevel,
  IMintCheck,
  IPayment,
  IPutCheckBurn,
  IPutCheckNft,
  IPutGameItemList,
  IStoryMint,
  ISubscriptNews,
  IWalletRequest,
} from '../types';

export const API_URL = 'https://dev-api.cyclub.io';

export const postWalletQR = () =>
  fetch(`${API_URL}/wallet/qr`, { method: 'POST' }).then((res) => res.json());

export const postInitMerchant = (body: {
  referenceId: number;
  itemName: string;
  itemDescription: string;
  amount: number;
  coin: string;
  payerFixed: boolean;
  payerWalletAddress: string | null;
}) =>
  fetch(`${API_URL}/payment/init-to-merchant`, {
    method: 'post',
    // @ts-ignore
    body,
  }).then((res) => res.json());

export const getCompletePayment = (paymentId: string) =>
  fetch(`${API_URL}/merchant/completed-payment/${paymentId}`).then((res) =>
    res.json()
  );

export const apiMetasoul_URL =
  'https://2pnrjgpsv4.ap-northeast-1.awsapprunner.com';

export const apiMetasoul_TEST_URL =
  'https://s3mmgb5fx2.ap-northeast-1.awsapprunner.com';

export const apiMetasoul = (
  url: string,
  method?: string,
  body?: BodyInit,
  headers?: HeadersInit
) =>
  fetch(`${apiMetasoul_URL}/api/v1${url}`, {
    body,
    headers,
    method,
  })
    .then((res: any) => (res.status !== 200 ? Promise.reject(res) : res.json()))
    .catch((e: any) => Promise.reject(e));

//User
export const connectWallet = (
  token: string,
  type: 'web3' | 'klaytn',
  data?: IWalletRequest
) =>
  apiMetasoul(`/connect/wallet`, 'POST', JSON.stringify(data), {
    'Content-Type': 'application/json',
    Authorization: token,
    'access-type': type,
  });

export const getWalletId = (id: string) => apiMetasoul(`/connect/wallet/${id}`);

export const getWalletList = (page: number) =>
  apiMetasoul(`/connect/wallet/list/${page}`);

//Item
export const postLevelInfo = (data: IItemLevel) =>
  apiMetasoul(`/items/level`, 'POST', JSON.stringify(data), {
    'Content-Type': 'application/json',
  });

export const postItemInfo = (data: IItemDetail) =>
  apiMetasoul(`/items/info`, 'POST', JSON.stringify(data), {
    'Content-Type': 'application/json',
  });

export const getItemList = (page: number) => apiMetasoul(`/items/list/${page}`);

export const getItemIdInfo = (id: number) => apiMetasoul(`/itmes/${id}/info`);

export const putChangeItemInfo = (id: string, data: IItemDetail) =>
  apiMetasoul(`/items/${id}`, 'PUT', JSON.stringify(data), {
    'Content-Type': 'application/json',
  });

//Payment
export const postQRpayment = (
  token: string,
  type: 'web3' | 'klaytn',
  data: IPayment
) =>
  apiMetasoul(`/payments/way/metamask`, 'POST', JSON.stringify(data), {
    'Content-Type': 'application/json',
    Authorization: token,
    'access-type': type,
  });

export const postCYpayment = (data: ICYPayment) =>
  apiMetasoul(`/payments/cy`, 'POST', JSON.stringify(data), {
    'Content-Type': 'application/json',
  });

export const getPaymentInfo = (paymentId: string) =>
  apiMetasoul(`/payments/transaction-code/${paymentId}`);

export const postCoinPayment = (
  token: string,
  type: 'web3' | 'klaytn',
  data: ICoinPayment
) =>
  apiMetasoul(`/payments/way/export/coin`, 'POST', JSON.stringify(data), {
    Authorization: token,
    'access-type': type,
    'Content-Type': 'application/json',
  });

//Subscription
export const postSubscriptNews = (data: ISubscriptNews) =>
  apiMetasoul(`/subscriptions/news`, 'POST', JSON.stringify(data), {
    'Content-Type': 'application/json',
  });

export const getSubscriptInfo = (email: string) =>
  apiMetasoul(`/subscriptions/emails/${email}`);

export const getSubscriptList = () => apiMetasoul(`/subscriptions/list`);

//Board
export const writePost = (data: IBoard) =>
  apiMetasoul(`/board/write`, 'POST', JSON.stringify(data), {
    'Content-Type': 'application/json',
  });

export const changePost = (id: string, data: IItemDetail) =>
  apiMetasoul(`/board/write/${id}`, 'PUT', JSON.stringify(data), {
    'Content-Type': 'application/json',
  });

export const getBoardList = (page: number) =>
  apiMetasoul(`/board/list/${page}`);

export const getBoardId = (id: number) => apiMetasoul(`/board/${id}`);

export const deletePost = (id: number) => apiMetasoul(`/board/${id}`, 'DELETE');

//webtoon

export const getWebtoonList = () => apiMetasoul(`/webtoon/list?page=1&take=7`);

export const getWebtoonEpisode = (title: string) =>
  apiMetasoul(`/webtoon?title=${title}`);

//item

export const putCheckBurn = (data: IPutCheckBurn) =>
  apiMetasoul('/items/check/burn', 'PUT', JSON.stringify(data), {
    'Content-Type': 'application/json',
  });

export const postCheckNft = (data: IPutCheckNft) =>
  apiMetasoul('/items/burn/check/nft', 'POST', JSON.stringify(data), {
    'Content-Type': 'application/json',
  });

export const postCheckMint = (data: IMintCheck) =>
  apiMetasoul('/items/check/mint', 'POST', JSON.stringify(data), {
    'Content-Type': 'application/json',
  });

export const getRealItemList = (miId: number) =>
  apiMetasoul(`/items/check?miId=${miId}`);

export const getGameItemList = (
  page: number,
  take: number,
  address: string,
  type: string
) =>
  apiMetasoul(
    `/items/list/user?page=${page}&take=${take}&address=${address}&type=${type}`
  );

export const putGameItemList = (data: IPutGameItemList) =>
  apiMetasoul('/items/mint', 'PUT', JSON.stringify(data), {
    'Content-Type': 'application/json',
  });

export const putExportItem = (data: IExportItem) =>
  apiMetasoul('/items/export/game', 'PUT', JSON.stringify(data), {
    'Content-Type': 'application/json',
  });

export const putBurnItem = (data: IBurnItem) =>
  apiMetasoul('/items/burn/nft', 'PUT', JSON.stringify(data), {
    'Content-Type': 'application/json',
  });

export const getPackData = (type: string) => apiMetasoul(`/items/${type}/mint`);

export const postPackData = (type: string, data: IStoryMint) =>
  apiMetasoul(`/items/mint/${type}`, 'POST', JSON.stringify(data), {
    'Content-Type': 'application/json',
  });

export const putPackData = (type: string, data: ICancelPack) =>
  apiMetasoul(`/items/${type}/mint/cancel`, 'PUT', JSON.stringify(data), {
    'Content-Type': 'application/json',
  });

export const getNftAmount = (type: string) =>
  apiMetasoul(`/items/${type}/amount`);

export const deleteBurnCheck = (data: IDeleteBurnCheck) =>
  apiMetasoul('/items/check/burn', 'DELETE', JSON.stringify(data), {
    'Content-Type': 'application/json',
  });

export const deleteMintCheck = (data: IMintCheck) =>
  apiMetasoul('/items/mint/reject', 'DELETE', JSON.stringify(data), {
    'Content-Type': 'application/json',
  });

//user

export const getUserId = (address: string) =>
  apiMetasoul(`/user/id?address=${address}`);

import { Modal, Box, Button, CircularProgress } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';

import {
  deleteMintCheck,
  getGameItemList,
  getRealItemList,
  getUserId,
  postCheckMint,
  putExportItem,
  putGameItemList,
} from '../../utils/api';
import { IGameItemList } from '../../types';
import { useRecoilState } from 'recoil';
import walletAccountAtom from '../../atoms/walletAccount';
import { itemContract } from '../../constants/contract';
import { create } from 'ipfs-http-client';

import ReactPlayer from 'react-player';
import IdFailed from '../Modal/IdFailed';
import MintingModal from '../Modal/MintingModal';
import { useTranslation } from 'react-i18next';
import CommonModal from '../Modal/CommonModal';
import web3 from '../../connection/web3';
import ProgressModal from '../Modal/ProgressModal';
import ExportSuccess from '../Modal/ExportSuccess';
import MintingSuccess from '../Modal/MintingSuccess';

interface IStorageProps {}

const client = create({
  host: 'ipfs.infura.io',
  port: 5001,
  protocol: 'https',
});

const tabs = ['FIGURE', 'STORY', 'CHARACTER'];

const Storage: React.FunctionComponent<IStorageProps> = () => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [list, setList] = React.useState<IGameItemList[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [idx, setIdx] = React.useState<number>(0);
  const [currentTab, setCurrentTab] = React.useState<string>('FIGURE');
  const [isFailOpen, setIsFailOpen] = React.useState<boolean>(false);
  const [isMintingOpen, setIsMintingOpen] = React.useState<boolean>(false);
  const [isError, setIsError] = React.useState<string>('');
  const [isCommonOpen, setIsCommonOpen] = React.useState<boolean>(false);
  const [currentProgress, setCurrentProgress] = React.useState<string>('');
  const [isProgressOpen, setIsProgressOpen] = React.useState<boolean>(false);
  const [isExportSuccess, setIsExportSuccess] = React.useState<boolean>(false);
  const [isMintingSuccess, setIsMintingSuccess] =
    React.useState<boolean>(false);

  const progressOpen = React.useCallback(() => {
    setIsProgressOpen(true);
  }, []);

  const handleMintingClose = React.useCallback(() => {
    setIsMintingOpen(false);
  }, []);

  const handleClickTab = (item: string) => () => {
    setCurrentTab(item);
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const handleFailOpen = React.useCallback(() => {
    setIsFailOpen(true);
  }, []);
  const handleFailClose = React.useCallback(() => {
    setIsFailOpen(false);
  }, []);

  const handleOpen = (i: number) => () => {
    setIsOpen(true);
    setIdx(i);
  };
  const handleClose = React.useCallback(() => setIsOpen(false), []);

  const [walletAccount, setWalletAccount] = useRecoilState(walletAccountAtom);

  const getItemList = React.useCallback(async () => {
    const result = await getGameItemList(
      1,
      //TODO 추후 가져오는 리스트 숫자 변경하기
      100,
      walletAccount.account,
      currentTab
    );
    setList(result);
  }, [currentTab, list]);

  const onClickMint = React.useCallback(async () => {
    setIsLoading(true);
    setIsProgressOpen(true);
    setCurrentProgress('NFT 민팅중입니다.');

    let miId = list[idx].miId;

    try {
      const gasPrice = await web3.eth.getGasPrice();

      const item = { ...list[idx] };
      // console.log('item..', item.itemInfo.sId);

      // delete item.sId

      // const { path } = await client.add(JSON.stringify(item));

      if (currentTab === 'FIGURE') {
        const getRealData = await getRealItemList(item.miId);

        const checkMint = await postCheckMint({ miId: item.miId });

        const unLockItem = await itemContract.methods
          .unlock(item.itemInfo.tokenId, item.key)
          .send({
            from: walletAccount.account,
            // value: 0,
            gas: 3000000,
            gasPrice,
          });
        const removeItemList = await putGameItemList({
          miId: Number(item.miId),
          tokenId: Number(item.itemInfo.tokenId),
        });
        setIsMintingSuccess(true);
      }
      if (currentTab !== 'FIGURE') {
        console.log('123123', item);

        const getRealData = await getRealItemList(item.miId);

        //TODO realData api 변경되었음.. true 확인 필요 없이 miId 보내고 진행하면 되는지 확인하고 아래로직 진행할것
        if (getRealData.message === 'TRUE') {
          const checkMint = await postCheckMint({ miId: item.miId });

          const mintItem = await itemContract.methods
            .mintNFT(
              currentTab === 'STORY'
                ? `https://metasoul.s3.ap-northeast-2.amazonaws.com/storyjson/${item.itemInfo.sId}.json`
                : `https://metasoul.s3.ap-northeast-2.amazonaws.com/characterjson/${item.itemInfo.description}.json`,
              currentTab === 'STORY' ? 0 : 1
            )
            .send({
              from: walletAccount.account,
              // value: 0,
              gas: 3000000,
              gasPrice,
            });
          const removeItemList = await putGameItemList({
            miId: Number(item.miId),
            tokenId: Number(item.itemInfo.tokenId),
          });
          setIsMintingSuccess(true);
        }
      }
    } catch (e: any) {
      console.log(e);
      const deleteItem = await deleteMintCheck({ miId });
    } finally {
      setTimeout(() => {
        getItemList();
        // console.log('delay');
      }, 500);
      setIsLoading(false);
      setIsOpen(false);
      setIsProgressOpen(false);
    }
  }, [idx, setIdx, list, setList, currentTab]);

  const onClickExportItem = React.useCallback(async () => {
    setIsLoading(true);
    setIsProgressOpen(true);
    setCurrentProgress('게임 내보내기 중입니다.');
    try {
      const getId = await getUserId(walletAccount.account);
      if (getId.message === 'Fail') {
        setIsFailOpen(true);
      } else {
        try {
          let item = { ...list[idx] };

          // console.log('item...', item.itemInfo.uId);

          const exportGameItem = await putExportItem({
            miId: Number(item.miId),
            address: walletAccount.account,
          });
          // console.log('ppp');

          setIsExportSuccess(true);
        } catch (e: any) {
          if (e.status === 400) {
            setIsError('UserId And Address MissMatching');
            setIsCommonOpen(true);
          }
          if (e.status === 401) {
            setIsError('유효한 게임 ID 값이 아닙니다.');
            setIsCommonOpen(true);
          }
          if (e.status === 402) {
            setIsError('유효한 아이템 ID 값이 아닙니다.');
            setIsCommonOpen(true);
          }
          if (e.status === 500) {
            setIsError('DB Error');
            setIsCommonOpen(true);
          }
          if (e.status === 1000) {
            setIsError('exception message');
            setIsCommonOpen(true);
          }
        }
      }
    } catch (e: any) {
      console.log(e);
    } finally {
      setTimeout(() => {
        getItemList();
        // console.log('delay');
      }, 500);
      setIsLoading(false);
      setIsOpen(false);
      setIsProgressOpen(false);
    }
  }, [idx, setIdx, currentTab, list, setList]);

  React.useEffect(() => {
    getItemList();
  }, [currentTab]);

  return (
    <>
      <StorageWrap>
        <TitleWrap>
          <StorageTitle>{t('storage.title')}</StorageTitle>
        </TitleWrap>
        <TabWrap>
          {tabs.map((item, i) => (
            <TabBtn key={i} onClick={handleClickTab(item)}>
              <TabTitle currentTab={currentTab} item={item}>
                {item}
              </TabTitle>
            </TabBtn>
          ))}
        </TabWrap>

        <CardGridWrap>
          {isLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: 400,
              }}
            >
              <CircularProgress />
            </div>
          ) : list?.length === 0 ? (
            <div style={{ width: '100%', height: '100%' }}>
              <h1 style={{ color: 'white', textAlign: 'center' }}>
                {t('storage.desc')}
              </h1>
            </div>
          ) : (
            list?.map((item, i: number) => (
              <Card key={i} onClick={handleOpen(i)}>
                {currentTab !== 'CHARACTER' ? (
                  <ReactPlayer
                    url={item?.itemInfo?.image}
                    width='100%'
                    height='100%'
                  />
                ) : (
                  <img
                    src={item?.itemInfo?.image}
                    style={{ width: 400, height: 400 }}
                  />
                )}
              </Card>
            ))
          )}
        </CardGridWrap>
        <Modal open={isOpen} onClose={handleClose}>
          <Box sx={CardModal}>
            <ModalCard>
              <CardContentsWrap>
                <ContentsLeft>
                  {currentTab !== 'CHARACTER' ? (
                    <ReactPlayer
                      url={list[idx]?.itemInfo?.image}
                      playing
                      muted
                      loop
                      width='100%'
                      height='100%'
                    />
                  ) : (
                    <img
                      src={list[idx]?.itemInfo?.image}
                      alt='img'
                      style={{ width: '100%', height: '100%' }}
                    />
                  )}
                </ContentsLeft>
                <ContentsRight>
                  <CloseBtn onClick={handleClose}>
                    <img src='/image/closegray.svg' />
                    {/* <CloseGray /> */}
                  </CloseBtn>

                  <DescWrap>
                    <div>
                      <CardTitle>{t('storage.card.name')}</CardTitle>
                      <Desc> {list[idx]?.itemInfo?.name}</Desc>
                    </div>
                    <div>
                      <CardTitle>{t('storage.card.desc')}</CardTitle>
                      <Desc> {list[idx]?.itemInfo?.description}</Desc>
                    </div>
                    <div>
                      <CardTitle>{t('storage.card.grade')}</CardTitle>
                      <Desc> {list[idx]?.itemInfo?.grade}</Desc>
                    </div>
                    {currentTab === 'FIGURE' && (
                      <>
                        <div>
                          <CardTitle>height</CardTitle>
                          <Desc> {list[idx]?.itemInfo?.height}</Desc>
                        </div>
                        <div>
                          <CardTitle>chest</CardTitle>
                          <Desc> {list[idx]?.itemInfo?.chest}</Desc>
                        </div>
                        <div>
                          <CardTitle>hip</CardTitle>
                          <Desc> {list[idx]?.itemInfo?.hip}</Desc>
                        </div>
                        <div>
                          <CardTitle>blood</CardTitle>
                          <Desc> {list[idx]?.itemInfo?.blood}</Desc>
                        </div>
                        <div>
                          <CardTitle>birth</CardTitle>
                          <Desc> {list[idx]?.itemInfo?.birth}</Desc>
                        </div>
                        <div>
                          <CardTitle>
                            {t('storage.card.constellation')}
                          </CardTitle>
                          <Desc> {list[idx]?.itemInfo?.constellation}</Desc>
                        </div>
                        <div>
                          <CardTitle>{t('storage.card.level')}</CardTitle>
                          <Desc> {list[idx]?.itemInfo?.level}</Desc>
                        </div>
                      </>
                    )}
                  </DescWrap>
                  <BtnWrap>
                    <MintButton>
                      <Button onClick={onClickMint}>{t('storage.mint')}</Button>
                    </MintButton>

                    <ExportButton>
                      <Button onClick={onClickExportItem}>
                        {t('storage.export')}
                      </Button>
                    </ExportButton>
                  </BtnWrap>
                  <p className='mint-desc'>{t('storage.nftdesc')}</p>
                </ContentsRight>
              </CardContentsWrap>
            </ModalCard>
          </Box>
        </Modal>
      </StorageWrap>
      {/* @ts-ignore */}
      <IdFailed open={isFailOpen} onClose={() => setIsFailOpen(false)} />
      {/* @ts-ignore */}
      <MintingModal open={isMintingOpen} onClose={handleClose} />
      {/* @ts-ignore */}
      <CommonModal
        open={isCommonOpen}
        onClose={() => setIsCommonOpen(false)}
        isError={isError}
      />
      {/* @ts-ignore */}
      <ProgressModal
        open={isProgressOpen}
        currentProgress={currentProgress}
        isLoading={isLoading}
      />
      {/* @ts-ignore */}
      <ExportSuccess
        open={isExportSuccess}
        onClose={() => setIsExportSuccess(false)}
      />
      {/* @ts-ignore */}
      <MintingSuccess
        open={isMintingSuccess}
        onClose={() => setIsMintingSuccess(false)}
      />
    </>
  );
};

export default React.memo(Storage);

const StorageWrap = styled.div`
  width: 1280px;
  background-color: #04080e;
  margin: 0 auto;
  border-radius: 15px;
`;

const CardGridWrap = styled.div`
  width: 1280px;
  background-color: #04080e;
  margin: 0 auto;

  display: flex;
  justify-content: flex-start;
  gap: 10px;
  flex-wrap: wrap;
  border-radius: 15px;
  padding: 15px;
`;

const Card = styled.div`
  width: 400px;
  margin-top: 20px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const CardModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  // height: 600,
  outline: 'none',
};

const ModalCard = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 900px;
  min-width: 900px;
  padding: 20px;
  border-radius: 16px;
  box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px 0px;
  background-color: #04080e;
  box-sizing: border-box;
`;

const CardContentsWrap = styled.div`
  display: flex;
  padding: 20px;
  gap: 20px;
`;

const ContentsLeft = styled.div`
  image {
    width: 100%;
    height: 100%;
    border-radius: 16px;
  }
  width: 50%;
`;
const ContentsRight = styled.div`
  width: 50%;
  .mint-desc {
    color: white;
    font-size: 14px;
    padding-left: 20px;
    padding-top: 20px;
    margin-bottom: 10px;
  }
`;

const DescWrap = styled.div`
  display: flex;
  flex-direction: column;
  div {
    display: flex;
    margin-left: 40px;
  }
`;

const CardTitle = styled.p`
  width: 30%;
  color: white;
`;

const Desc = styled.p`
  width: 70%;
  font-size: 14px;
  line-height: 1.5;
  color: white;
  text-align: left;
  white-space: pre-line;
`;

const CloseBtn = styled(Button)`
  /* padding-left: 96%; */
  margin-left: 90% !important;
`;

const StorageTitle = styled.h1`
  text-align: center;
`;

const TitleWrap = styled.div`
  width: 1280px;
  height: 100px;
  background-color: #04080e;
  margin: 0 auto;
  border-radius: 15px;
  margin-top: 60px;
  display: flex;

  justify-content: center;
  align-items: center;
`;

const MintButton = styled.div`
  width: 40%;
  background-color: #565fd6;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    width: 100%;
    color: white;
  }
`;

const ExportButton = styled.div`
  width: 40%;
  background-color: #565fd6;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    width: 100%;
    color: white;
  }
`;

const BtnWrap = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;
`;

const TabWrap = styled.div`
  width: 1280px;
  background-color: #04080e;
  margin: 0 auto;
  border-radius: 15px;
  /* margin-top: 60px; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
`;

interface ITabBtn {
  currentTab?: string;
  item?: string;
}

const TabBtn = styled(Button)<ITabBtn>`
  cursor: pointer;
`;

const TabTitle = styled.h1<ITabBtn>`
  &:hover {
    color: #1fc7d4 !important;
  }
  font-family: GyeonggiBatang;
  color: ${({ currentTab, item }) =>
    currentTab === item ? '#1fc7d4' : 'white'};
`;

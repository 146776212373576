import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

const priceAtom = atom<number>({
  key: 'klayPrice',
  default: 0,
  effects_UNSTABLE: [persistAtom],
});

export default priceAtom;

import React from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import {
  WebtoonWrap,
  WebtoonTitle,
  WebtoonCard,
  WebtoonCardWrap,
  OpacityWrap,
} from './styles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import moment from 'moment';

const now = moment().format('YYYY-MM-DD HH:mm:ss');
export const webtoonOpenDate = [
  '2022-03-09 00:00:00',
  '2022-03-16 00:00:00',
  '2022-03-23 00:00:00',
  '2022-03-30 00:00:00',
  '2022-04-06 00:00:00',
  '2022-04-13 00:00:00',
  '2022-04-20 00:00:00',
];

const WebtoonPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <WebtoonWrap>
        <WebtoonTitle>Metasoul Webtoon</WebtoonTitle>
        <WebtoonCardWrap>
          {(
            t('webtoon.webtooncard', { returnObjects: true }) as Array<{
              episode: string;
              webtoonbtn: string;
            }>
          ).map(({ episode, webtoonbtn }, i: number) => {
            // const isClose = moment(
            //   webtoonOpenDate[i],
            //   'YYYY-MM-DD HH:mm:ss'
            // ).isAfter(now);

            return (
              <WebtoonCard key={i}>
                <h1>{episode}</h1>
                <div className='img-wrap'>
                  <img src='/image/webtoonlogo.png' alt='Metasoul' />
                </div>
                {/* isClose ? '#' : */}
                <Link to={`/webtoon/${i + 1}`}>{webtoonbtn}</Link>
                {/* {isClose && <OpacityWrap>매주 수요일 공개 예정</OpacityWrap>} */}
              </WebtoonCard>
            );
          })}
        </WebtoonCardWrap>
      </WebtoonWrap>
      <Footer />
    </>
  );
};

export default WebtoonPage;

import styled from 'styled-components';

export const App = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  color: white;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span {
    margin: 0;
  }
`;

export const Wrap = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  background-color: #04080e;
  border-radius: 15px;
  padding: 80px 0 80px 40px;
  & > div {
    width: 50%;
    aspect-ratio: 1;
    position: relative;
    &:first-child {
      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
  }
  /* @media (max-width: 1400px) {
    padding: 40px 30px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    & > div {
      width: 100%;
    }
  } */
`;

export const InfoWrap = styled.div`
  padding-left: 100px;
  box-sizing: border-box;
  & > .title {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  & > .stock {
    margin-bottom: 40px;
    font-size: 30px;
  }
  /* @media (max-width: 768px) {
    margin-top: 40px;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  } */
`;

interface IInfoMenuButton {
  isActive?: boolean;
}

export const InfoMenuWrap = styled.div`
  display: flex;

  width: 130px;
  border-radius: 50px;
  background-color: #04080e;
  margin-bottom: 20px;
`;

export const InfoMenuButton = styled.button<IInfoMenuButton>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 20px 0;
  border: 0;
  border-radius: 50px;
  background-color: ${(props) => (props.isActive ? '#1A2147' : 'transparent')};
  color: white;
  cursor: pointer;
  & > img {
    margin-right: 10px;
  }
`;

export const CountWrap = styled.div`
  margin-bottom: 40px;
  & > p {
    margin-bottom: 10px;
    font-size: 20px;
  }
  .content {
    display: flex;
    width: 250px;
    & > button {
      width: 50px;
      height: 50px;
      border: 0;
      background-color: rgb(25, 33, 71);
      cursor: pointer;
    }
    & > p {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  /* @media (max-width: 768px) {
    p {
      text-align: center;
    }
  } */
`;
export const PriceWrap = styled.div`
  margin-bottom: 20px;
  .title {
    margin-bottom: 5px;
    font-size: 20px;
  }
  .desc {
    font-size: 1.5rem;
  }
  /* @media (max-width: 768px) {
    p {
      text-align: center;
    }
  } */
`;

export const TotalPriceWrap = styled.div`
  .title {
    margin-bottom: 5px;
    font-size: 20px;
  }
  .desc {
    font-size: 2rem;
    color: #04dde9;
    font-weight: bold;
  }

  /* @media (max-width: 768px) {
    p {
      text-align: center;
    }
  } */
`;

export const BuyButton = styled.button`
  margin-top: 20px;
  width: 95%;
  border: 0;
  border-radius: 10px;
  background-color: #1a2147;
  font-size: 1rem;
  font-weight: bold;
  color: white;
  padding: 20px 80px;
  font-size: 30px;
  cursor: pointer;
`;

export const FigureInfoWrap = styled.div`
  margin-top: 40px;
  padding: 10px;
`;

export const InfoData = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  h1 {
    color: white;
  }
`;

export const FigureDescription = styled.div`
  margin-top: 20px;
  line-height: 1.8;
  padding: 10px;
  font-size: 20px;
  white-space: pre-line;
  text-align: center;
`;

export const FigureSizeWrap = styled.div`
  margin-top: 60px;
`;

export const LineWrap = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* margin-top: 10px; */
  padding: 15px;
  border-bottom: 1px solid rgb(30, 36, 63);

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    p {
      margin-top: 5px;
    }
  }
`;

export const TableInfo = styled.div`
  border-top: 1px solid rgb(30, 36, 63);

  h2,
  p {
    font-size: 20px;
    width: 50%;
    text-align: center;
  }
`;

export const LevelWrap = styled.div`
  margin-top: 40px;
  padding: 10px;
`;

export const LevelTableWrap = styled.div`
  margin-top: 60px;
`;

export const Title = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  max-width: 620px;
  width: 100%;
  height: 70px;
  border-radius: 8px;
  background-color: rgb(25, 33, 71);
  color: rgb(233, 240, 254);
  font-size: 1.125rem;
  line-height: 1.40625rem;
  font-weight: bold;
  font-size: 30px;
`;

export const BuyDescription = styled.div`
  .text-wrap {
    color: white;
    font-size: 20px;
    line-height: 1.5;
  }

  h2 {
    font-size: 20px;
    margin-top: 20px;
    color: #04dde9;
  }
`;

export const BuyStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  outline: 'none',
};

export const BuyBox = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 500px;
  padding: 20px;
  border-radius: 16px;
  box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px 0px;
  background-color: #ffffff;
  box-sizing: border-box;

  text-align: center;
  align-items: center;
`;

export const QrArea = styled.div`
  width: 220px;
  height: 220px;
  margin-top: 20px;
`;

export const PaySection = styled.div`
  display: flex;

  flex-direction: column;
  align-items: center;

  .close-wrap {
    position: absolute;
    left: 90%;
    cursor: pointer;
  }
  p {
    margin-top: 40px;
    width: 390px;
    text-align: left;
    line-height: 1.5;
  }
  h2 {
    margin-top: 40px;
  }
`;

export const EventDescription = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  text-align: center;
  background-color: #1a2147;
  padding: 20px;
  border-radius: 15px;
  h1 {
    font-size: 25px;
    color: #04dde9;
    font-weight: bold;
    white-space: pre-line;
    line-height: 1.5;
    margin-top: 10px;
  }
  div {
    margin-top: 20px;
    font-size: 20px;
    white-space: pre-line;
    line-height: 1.5;
    margin-bottom: 10px;
  }
  p {
    line-height: 1.5;
  }
`;

export const CountdownWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20px 0;
  margin-top: 20px;
  margin-bottom: 20px;
  .item-wrap {
    display: flex;
    flex-direction: column;
    width: 100px;
    .item {
      background-color: white;
      height: 100px;
      line-height: 100px;
      margin-bottom: 5px;
      border-radius: 10px;
      text-align: center;
      font-size: 60px;
    }
    .title {
      color: black;
      font-weight: bold;
      text-align: center;
    }
  }
`;

export const MintingTitle = styled.div`
  margin-top: 25px;
  font-weight: bold;
  font-size: 20px;
`;

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Footer from '../../components/Footer';
import Header from '../../components/Header';

interface ITermsProps {}

const Terms: React.FunctionComponent<ITermsProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <TermsWrap>
        <Tilte>{t('terms.title1')}</Tilte>
        <Desc>{t('terms.desc1')}</Desc>
        <Tilte>{t('terms.title2')}</Tilte>
        <Desc>{t('terms.desc2')}</Desc>
        <Tilte>{t('terms.title3')}</Tilte>
        <Desc>{t('terms.desc3')}</Desc>
        <Tilte>{t('terms.title4')}</Tilte>
        <Desc>{t('terms.desc4')}</Desc>
        {/* /// */}
        <Tilte>{t('terms.title5')}</Tilte>
        <Desc>{t('terms.desc5')}</Desc>
        {/* /// */}
        <Tilte>{t('terms.title6')}</Tilte>
        <Desc>{t('terms.desc6')}</Desc>
        {/* /// */}
        <Tilte>{t('terms.title7')}</Tilte>
        <Desc>{t('terms.desc7')}</Desc>
        <Alphabet>{t('terms.alphabet1')}</Alphabet>
        <Desc>{t('terms.desc8')}</Desc>
        <Desc>{t('terms.desc9')}</Desc>
        {/* /// */}
        <Tilte>{t('terms.title8')}</Tilte>
        <Desc>{t('terms.desc10')}</Desc>
        {/* /// */}
        <Tilte>{t('terms.title9')}</Tilte>
        <Desc>{t('terms.desc11')}</Desc>
        <Alphabet>{t('terms.alphabet2')}</Alphabet>
        {/* /// */}

        <Tilte>{t('terms.title12')}</Tilte>
        <Desc>{t('terms.desc16')}</Desc>

        {/* /// */}
        <Tilte>{t('terms.title10')}</Tilte>
        <Desc>{t('terms.desc12')}</Desc>
        <Alphabet>{t('terms.alphabet3')}</Alphabet>
        <Desc>{t('terms.desc13')}</Desc>
        {/* /// */}
        <Tilte>{t('terms.title11')}</Tilte>
        <Desc>{t('terms.desc14')}</Desc>
        <Desc>{t('terms.desc15')}</Desc>
      </TermsWrap>
      <Footer />
    </>
  );
};

export default Terms;

const TermsWrap = styled.div`
  min-width: 1920px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 100px 400px;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

const Tilte = styled.h1``;

const Desc = styled.p`
  line-height: 1.5;
  white-space: pre-line;
`;

const Alphabet = styled.p`
  white-space: pre-line;
  line-height: 2;
  padding-left: 5%;
  padding-right: 5%;
`;

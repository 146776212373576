import React from "react";
import ReactPlayer from "react-player";
import i18n from "../i18n";
import { isMobile } from "react-device-detect";

interface IVideo {
  open?: boolean;
}

const Video: React.FunctionComponent<IVideo> = ({ open }) => {
  return (
    <ReactPlayer
      url={
        i18n.language === "en"
          ? "/video/MetaSoul_EN_Teaser.mp4"
          : "/video/MetaSoul_KR_Teaser.mp4"
      }
      width={open ? 1500 : 1050}
      height={open ? 1200 : 600}
      playsinline
      playing
      controls={open}
      muted={!open}
      loop
      volume={0.5}
      // light={open ? "/image/thumbnail.png" : false}
      // style={{ paddingBottom: "30px" }}
    />
  );
};

export default Video;

import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @font-face {
      font-family: 'GyeonggiBatang';
      src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_one@1.0/GyeonggiBatang.woff') format('woff');
      font-weight: normal;
      font-style: normal;
  }
  
  * {
    box-sizing: border-box;
    .swiper-button-prev, .swiper-button-next{
      display: none;
    }
    body{
      font-family: GyeonggiBatang;
      margin: 0;
      min-height: 100vh;
      background-color: black;
    }
    ul{
      list-style-type: none;
      padding-left:0px;
    }
  }
`;

export default GlobalStyle;

import styled from 'styled-components';

export const IntroWrap = styled.div`
  min-width: 1920px;
  margin: 0 auto;
  background-image: url('/image/introbg.jpg');
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100;
  background-color: #000101;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: GyeonggiBatang;
  -webkit-text-size-adjust: none; /*Chrome, Safari, newer versions of Opera*/
  -moz-text-size-adjust: none; /*Firefox*/
  -ms-text-size-adjust: none; /*Ie*/
  -o-text-size-adjust: none; /*old versions of Opera*/
`;

export const LogoWrap = styled.div`
  margin-top: 100px;
  img {
    width: 890px;
    height: 171px;
  }
`;

export const TitleWrap = styled.h1`
  margin-top: 40px;
  font-size: 35px;
  font-weight: bold;
  font-style: oblique;
  white-space: nowrap;
  color: white;
`;

export const SubTitle = styled.div`
  font-size: 30px;
  color: white;
  white-space: nowrap;
  font-weight: bold;
`;

export const StoryDetail = styled.div`
  margin-top: 40px;
  white-space: pre-line;
  display: flex;
  justify-content: center;
  text-align: center;
  line-height: 1.5;
  font-size: 25px;

  p {
    color: white;
  }
  span {
    color: #ffe27e;
    font-weight: bold;
  }
`;

export const PlayDetail = styled.div`
  margin-top: 30px;
  font-size: 25px;
  white-space: pre-line;
  line-height: 1.5;
  img {
    width: 800px;
  }
  p {
    color: white;
    text-align: center;
  }
  span {
    color: #ffe27e;
    font-weight: bold;
  }
`;

export const CardImgWrap = styled.div`
  margin-top: 61px;
  img {
    width: 700px;
  }
`;

export const CardDetail = styled.div`
  font-size: 25px;
  text-align: center;
  white-space: pre-line;
  line-height: 1.5;
  p {
    color: white;
    text-align: center;
  }
  span {
    color: #ffe27e;
    font-weight: bold;
  }
`;

export const StoryModeDetail = styled.div`
  font-size: 25px;
  text-align: center;
  white-space: pre-line;
  line-height: 1.5;
  p {
    color: white;
    text-align: center;
  }
  span {
    color: #ffe27e;
    font-weight: bold;
  }
`;

export const CollectImgWrap = styled.div`
  img {
    margin-top: 50px;
    width: 1200px;
  }
`;

export const CollectDetail = styled.div`
  white-space: pre-line;
  font-size: 25px;
  text-align: center;
  line-height: 1.5;
  p {
    color: white;
    text-align: center;
  }
  span {
    color: #ffe27e;
    font-weight: bold;
  }
`;

export const StoryImgWrap = styled.div`
  margin-top: 60px;
  img {
    width: 1200px;
  }
`;

export const EarnImgWrap = styled.div`
  img {
    margin-top: 53px;
    width: 700px;
  }
`;

export const EarnDetail = styled.div`
  margin-top: 49px;
  margin-bottom: 250px;
  font-size: 25px;
  white-space: pre-line;
  line-height: 1.5;
  p {
    color: white;
    text-align: center;
  }
  span {
    color: #ffe27e;
    font-weight: bold;
  }
`;

export const NftWrap = styled.div`
  img {
    margin-top: 38px;
  }
`;

export const NftDetail = styled.div`
  font-size: 25px;
  margin-bottom: 180px;
  p {
    color: white;
  }
  .text-gradient {
    color: #ffeeb5;
  }
`;

export const RightWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 20px;
  color: #ffe27e;
  font-weight: bold;
  margin-top: 15px;
`;

import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import WebtoonDetail from './WebtoonDetail';
import { WebtoonWrap } from './styles';
import { webtoonOpenDate } from '..';
import moment from 'moment';

interface IWebtoonProps {}

const now = moment().format('YYYY-MM-DD HH:mm:ss');

export const webtoonPageWL = webtoonOpenDate
  .map((item, i: number) => {
    if (moment(item, 'YYYY-MM-DD HH:mm:ss').isBefore(now)) {
      return (i + 1).toString();
    }
  })
  .filter((el) => el != null);

export const isIncludeWebtoonPageWL = (number: string) =>
  webtoonPageWL.includes(number);

const WebtoonDetailPage: React.FunctionComponent<IWebtoonProps> = () => {
  const { name } = useParams() as { name: string };
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!isIncludeWebtoonPageWL(name)) {
      navigate('/', { replace: true });
    }
  }, []);

  return (
    <>
      <Header />
      <WebtoonWrap>
        <WebtoonDetail name={name} />
      </WebtoonWrap>
      <Footer />
    </>
  );
};

export default WebtoonDetailPage;

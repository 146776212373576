import {
  Modal,
  Box,
  Button,
  ModalProps,
  CircularProgress,
} from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';

interface IProgressModalProps extends ModalProps {
  currentProgress: string;
  isLoading: boolean;
}

const ProgressModal: React.FunctionComponent<IProgressModalProps> = (props) => {
  const { currentProgress, isLoading, ...rest } = props;

  return (
    <>
      <Modal {...rest}>
        <Box sx={ModalCard}>
          <CommonCard>
            <h2>{currentProgress}</h2>
            <h2>처리중 새로고침을 누르지 마세요.</h2>
            {isLoading && <CircularProgress />}
          </CommonCard>
        </Box>
      </Modal>
    </>
  );
};

export default ProgressModal;

const ModalCard = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  outline: 'none',
};

const CommonCard = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 500px;
  padding: 20px;
  border-radius: 16px;
  box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px 0px;
  background-color: #04080e;
  box-sizing: border-box;
  text-align: center;
  align-items: center;
  h2 {
    color: white;
  }
  p {
    color: white;
  }
`;

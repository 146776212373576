import React, { useEffect } from 'react';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Route, Routes, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { RecoilRoot } from 'recoil';
import Providers from './providers/providers';
import {
  GameInfo,
  Mint,
  MintDetail,
  Mypage,
  NoMatch,
  Home,
  Webtoon,
  WebtoonDetail,
  NftInfo,
} from './pages';
import Packs from './pages/Packs';
import Terms from './pages/Terms';
import Personal from './pages/Personal';
import ExportInfo from './pages/ExportInfo';

function App() {
  useEffect(() => {
    ReactGA.initialize('G-NBK52SQV4D');
    ReactGA.send('pageview');
  }, []);

  return (
    <RecoilRoot>
      <Providers>
        <React.Suspense fallback={<div>...loading</div>}>
          <ScrollToTop />
          <Routes>
            <Route index element={<Home />} />
            {/* <Route path='/mint/:name' element={<MintDetail />} /> */}
            <Route path='/packs/:name' element={<Packs />} />
            <Route path='/mint' element={<Mint />} />
            {/* @ts-ignore */}
            <Route path='/mypage' element={<Mypage />} />
            <Route path='/game-info' element={<GameInfo />} />
            <Route path='/nft-info' element={<NftInfo />} />
            <Route path='/webtoon/:name' element={<WebtoonDetail />} />
            <Route path='/webtoon' element={<Webtoon />} />
            <Route path='/terms' element={<Terms />} />
            <Route path='/personal' element={<Personal />} />
            <Route path='/export-info' element={<ExportInfo />} />
            <Route path='*' element={<NoMatch />} />
          </Routes>
        </React.Suspense>
      </Providers>
    </RecoilRoot>
  );
}

// 리액트 라우터 v6에서 useHistory 사라짐, useHistory 기능은 useNavigate가 담당
// navigate.listen 이런식으로는 사용이 안되서 location으로 대체하여 사용함

const ScrollToTop = () => {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return null;
};

export default App;

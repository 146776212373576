import * as React from 'react';
import { StyledFooter, FooterImage, Move } from '../pages/Home/styles';
import i18n from '../i18n';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface IFooterProps {}

const Footer: React.FunctionComponent<IFooterProps> = () => {
  const { t } = useTranslation();

  const items = [
    { href: 'http://discord.io/metasoul', src: '/image/ft-discord.png' },
    {
      href:
        i18n.language === 'en'
          ? 'https://t.me/MetaSoul_GL'
          : 'https://t.me/MetaSoul_KR',
      src: '/image/ft-telegram.png',
    },
    { href: 'https://medium.com/@META-Soul', src: '/image/ft-medium.png' },
    { href: 'https://twitter.com/MetaSoul_GL', src: '/image/ft-twitter.png' },
    {
      href: 'https://www.instagram.com/metasoulnft/',
      src: '/image/ft-instagram.png',
    },
    {
      href:
        i18n.language === 'en'
          ? 'https://soul-meta.gitbook.io/meta-soul_en/'
          : 'https://soul-meta.gitbook.io/meta-soul/',
      src: '/image/ft-gitbook.png',
    },
  ];

  return (
    <StyledFooter>
      <div className='btn-wrap'>
        <Button>
          <Move to='/terms'>{t('terms.title0')}</Move>
        </Button>
        <Button>
          <Move to='/personal'>{t('personal.title0')}</Move>
        </Button>
      </div>
      <div className='footer-icons'>
        {items.map((item, i: number) => (
          <a
            key={`footer_item_${i}`}
            href={item.href}
            target='_blank'
            rel='noopener noreferrer'
          >
            <FooterImage src={item.src} />
          </a>
        ))}
      </div>
    </StyledFooter>
  );
};

export default Footer;
